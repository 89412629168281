// This file contains Italian translations

import { Translations } from '../types';

const it: Translations['it'] = {
  appTitle: "MongMong",
  uploadImage: "Carica immagine",
  selectLanguage: "Seleziona lingua",
  translate: "Traduci",
  sourceLanguage: "Lingua di origine",
  targetLanguage: "Lingua di destinazione",
  enterText: "Inserisci il testo da tradurre",
  translationResults: "Risultati della traduzione",
  cameraButtonLabel: "Scatta foto",
  microphoneButtonLabel: "Inizia registrazione",
  speakerButtonLabel: "Riproduci audio",
  contextSelectorLabel: "Seleziona contesto",
  settings: "Impostazioni",
  language: "Lingua",
  gender: "Genere",
  genderUnknown: "Sconosciuto",
  genderMale: "Maschile",
  genderFemale: "Femminile",
  emojiUsage: "Utilizzo emoji",
  emojiUsageNever: "Mai",
  emojiUsageSometimes: "A volte",
  emojiUsageAlways: "Sempre",
  version: "Versione",
  releaseDate: "Data di rilascio",
  close: "Chiudi",
  clear: "Cancella",
  explain: "Spiega",
  additionalContext: "Contesto aggiuntivo",
  recording: "Registrazione in corso...",
  idiomDetected: "Espressione idiomatica rilevata",
  copy: "Copia",
  en: "Inglese (English)",
  ja: "Giapponese (日本語)",
  ko: "Coreano (한국어)",
  "zh-CN": "Cinese (semplificato) (简体中文)",
  "zh-TW": "Cinese (tradizionale) (繁體中文)",
  dk: "Danese (Dansk)",
  fr: "Francese (Français)",
  vi: "Vietnamita (Tiếng Việt)",
  sv: "Svedese (Svenska)",
  de: "Tedesco (Deutsch)",
  es: "Spagnolo (Español)",
  ar: "Arabo (العربية)",
  bn: "Bengali (বাংলা)",
  ru: "Russo (Русский)",
  uk: "Ucraino (Українська)",
  ceb: "Cebuano",
  tl: "Tagalog",
  it: "Italiano",
  el: "Greco (Ελληνικά)",
  "ko (nk)": "Coreano, dialetto della Corea del Nord (조선말)",
  casual: "Informale",
  social_media: "Social media",
};

export default it;