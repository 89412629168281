import React, { createContext, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { LanguageKey, TranslationVariant, TranslationContextType, TranslationFunction, GenderKey, EmojiUsageKey } from '../types';
import { useTranslation } from '../hooks/useTranslation';
import { logger } from '../utils/logger';
import { GENDER_STORAGE_KEY, EMOJI_USAGE_STORAGE_KEY } from '../utils/constants';

const TranslationContext = createContext<TranslationContextType | undefined>(undefined);

export const useTranslationContext = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslationContext must be used within a TranslationProvider');
  }
  return context;
};

const LANGUAGE_STORAGE_KEY = 'preferredLanguage';

const getInitialLanguage = (): LanguageKey => {
  const savedLang = localStorage.getItem(LANGUAGE_STORAGE_KEY) as LanguageKey | null;
  const browserLang = navigator.language.split('-')[0] as LanguageKey;
  return savedLang || browserLang;
};

const getInitialGender = (): GenderKey => {
  const savedGender = localStorage.getItem(GENDER_STORAGE_KEY) as GenderKey | null;
  return savedGender || 'unknown';
};

const getInitialEmojiUsage = (): EmojiUsageKey => {
  const savedEmojiUsage = localStorage.getItem(EMOJI_USAGE_STORAGE_KEY) as EmojiUsageKey | null;
  return savedEmojiUsage || 'sometimes';
};

export const TranslationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [sourceLang, setSourceLang] = useState<LanguageKey>('en');
  const [targetLang, setTargetLang] = useState<LanguageKey>('ja');
  const [uiLang, setUiLang] = useState<LanguageKey>(getInitialLanguage());
  const [gender, setGender] = useState<GenderKey>(getInitialGender());
  const [emojiUsage, setEmojiUsage] = useState<EmojiUsageKey>(getInitialEmojiUsage());
  const [sourceText, setSourceText] = useState('');
  const [isProcessingImage, setIsProcessingImage] = useState(false);

  const {
    isTranslating,
    translations,
    handleTranslate: originalHandleTranslate,
    handleExplain: originalHandleExplain,
    handleExplanationImage: originalHandleExplanationImage,
    cancelTranslation,
    clearTranslations,
    changeLanguage,
    t,
  } = useTranslation();

  useEffect(() => {
    changeLanguage(uiLang);
  }, [changeLanguage, uiLang]);

  const handleSetSourceLang = useCallback((lang: LanguageKey) => {
    logger.debug('Setting source language', { lang });
    setSourceLang(lang);
  }, []);

  const handleSetTargetLang = useCallback((lang: LanguageKey) => {
    logger.debug('Setting target language', { lang });
    setTargetLang(lang);
  }, []);

  const handleSetUiLang = useCallback((lang: LanguageKey) => {
    logger.debug('Setting UI language', { lang });
    setUiLang(lang);
    localStorage.setItem(LANGUAGE_STORAGE_KEY, lang);
  }, []);

  const handleSetGender = useCallback((newGender: GenderKey) => {
    logger.debug('Setting gender', { newGender });
    setGender(newGender);
    localStorage.setItem(GENDER_STORAGE_KEY, newGender);
  }, []);

  const handleSetEmojiUsage = useCallback((newEmojiUsage: EmojiUsageKey) => {
    logger.debug('Setting emoji usage', { newEmojiUsage });
    setEmojiUsage(newEmojiUsage);
    localStorage.setItem(EMOJI_USAGE_STORAGE_KEY, newEmojiUsage);
  }, []);

  const handleTranslate = useCallback((inputText: string, additionalContext: string, signal: AbortSignal) => {
    logger.debug('Handling translate', { inputText, additionalContext, sourceLang, targetLang, gender, emojiUsage });
    const genderContext = gender !== 'unknown' ? `the speaker is ${gender === 'male' ? 'a male' : 'a female'}` : '';
    const emojiContext = `emoji usage is set to ${emojiUsage}`;
    const fullContext = `${additionalContext}${genderContext ? ' ' + genderContext : ''} ${emojiContext}`;
    originalHandleTranslate(inputText, fullContext, sourceLang, targetLang, signal);
  }, [originalHandleTranslate, sourceLang, targetLang, gender, emojiUsage]);

  const handleExplain = useCallback((inputText: string, additionalContext: string, signal: AbortSignal) => {
    logger.debug('Handling explain', { inputText, additionalContext, sourceLang, targetLang, gender, emojiUsage });
    const genderContext = gender !== 'unknown' ? `the speaker is ${gender === 'male' ? 'a male' : 'a female'}` : '';
    const emojiContext = `emoji usage is set to ${emojiUsage}`;
    const fullContext = `${additionalContext}${genderContext ? ' ' + genderContext : ''} ${emojiContext}`;
    originalHandleExplain(inputText, fullContext, sourceLang, targetLang, signal);
  }, [originalHandleExplain, sourceLang, targetLang, gender, emojiUsage]);

  const handleExplanationImage = useCallback((imageContent: string, signal: AbortSignal) => {
    logger.debug('Handling explanation image', { sourceLang, targetLang, gender, emojiUsage });
    const genderContext = gender !== 'unknown' ? `the speaker is ${gender === 'male' ? 'a male' : 'a female'}` : '';
    const emojiContext = `emoji usage is set to ${emojiUsage}`;
    const additionalContext = `${genderContext}${genderContext ? ' ' : ''}${emojiContext}`;
    return originalHandleExplanationImage(imageContent, sourceLang, targetLang, signal);
  }, [originalHandleExplanationImage, sourceLang, targetLang, gender, emojiUsage]);

  const value: TranslationContextType = useMemo(() => ({
    sourceLang,
    targetLang,
    uiLang,
    gender,
    emojiUsage,
    isTranslating,
    translations,
    sourceText,
    isProcessingImage,
    setSourceLang: handleSetSourceLang,
    setTargetLang: handleSetTargetLang,
    setUiLang: handleSetUiLang,
    setGender: handleSetGender,
    setEmojiUsage: handleSetEmojiUsage,
    setSourceText,
    handleTranslate,
    handleExplain,
    handleExplanationImage,
    cancelTranslation,
    clearTranslations,
    setIsProcessingImage,
    t,
  }), [
    sourceLang,
    targetLang,
    uiLang,
    gender,
    emojiUsage,
    isTranslating,
    translations,
    sourceText,
    isProcessingImage,
    handleSetSourceLang,
    handleSetTargetLang,
    handleSetUiLang,
    handleSetGender,
    handleSetEmojiUsage,
    handleTranslate,
    handleExplain,
    handleExplanationImage,
    cancelTranslation,
    clearTranslations,
    t,
  ]);

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};