import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, SxProps, Theme } from '@mui/material';
import { ContextKey } from '../types';
import { contexts } from '../utils/constants';

interface ContextSelectorProps {
  value: ContextKey;
  onChange: (context: ContextKey) => void;
  sx?: SxProps<Theme>;
}

const ContextSelector: React.FC<ContextSelectorProps> = ({ value, onChange, sx }) => {
  return (
    <FormControl fullWidth size="small" sx={sx}>
      <InputLabel>Context</InputLabel>
      <Select
        value={value}
        label="Context"
        onChange={(e) => onChange(e.target.value as ContextKey)}
      >
        {Object.entries(contexts).map(([key, name]) => (
          <MenuItem key={key} value={key}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ContextSelector;