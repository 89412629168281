import { Translations } from '../types';

const sv: Translations['sv'] = {
  appTitle: "MongMong",
  uploadImage: "Ladda upp bild",
  selectLanguage: "Välj språk",
  translate: "Översätt",
  sourceLanguage: "Källspråk",
  targetLanguage: "Målspråk",
  enterText: "Ange text att översätta",
  translationResults: "Översättningsresultat",
  cameraButtonLabel: "Ta foto",
  microphoneButtonLabel: "Starta inspelning",
  speakerButtonLabel: "Spela ljud",
  contextSelectorLabel: "Välj kontext",
  settings: "Inställningar",
  language: "Språk",
  gender: "Kön",
  genderUnknown: "Okänt",
  genderMale: "Man",
  genderFemale: "Kvinna",
  emojiUsage: "Emojianvändning",
  emojiUsageNever: "Aldrig",
  emojiUsageSometimes: "Ibland",
  emojiUsageAlways: "Alltid",
  version: "Version",
  releaseDate: "Utgivningsdatum",
  close: "Stäng",
  clear: "Rensa",
  explain: "Förklara",
  additionalContext: "Ytterligare kontext",
  recording: "Spelar in...",
  idiomDetected: "Idiom upptäckt",
  copy: "Kopiera",
  en: "Engelska (English)",
  ja: "Japanska (日本語)",
  ko: "Koreanska (한국어)",
  "zh-CN": "Kinesiska (förenklad) (简体中文)",
  "zh-TW": "Kinesiska (traditionell) (繁體中文)",
  dk: "Danska (Dansk)",
  fr: "Franska (Français)",
  vi: "Vietnamesiska (Tiếng Việt)",
  sv: "Svenska",
  de: "Tyska (Deutsch)",
  es: "Spanska (Español)",
  ar: "Arabiska (العربية)",
  bn: "Bengali (বাংলা)",
  ru: "Ryska (Русский)",
  uk: "Ukrainska (Українська)",
  ceb: "Cebuano",
  tl: "Tagalog",
  it: "Italienska (Italiano)",
  el: "Grekiska (Ελληνικά)",
  "ko (nk)": "Koreanska, nordkoreansk dialekt (조선말)",
  casual: "Informell",
  social_media: "Sociala medier",
};

export default sv;