// This file contains Spanish translations

import { Translations } from '../types';

const es: Translations['es'] = {
  appTitle: "MongMong",
  uploadImage: "Subir imagen",
  selectLanguage: "Seleccionar idioma",
  translate: "Traducir",
  sourceLanguage: "Idioma de origen",
  targetLanguage: "Idioma de destino",
  enterText: "Ingrese texto para traducir",
  translationResults: "Resultados de la traducción",
  cameraButtonLabel: "Tomar foto",
  microphoneButtonLabel: "Iniciar grabación",
  speakerButtonLabel: "Reproducir audio",
  contextSelectorLabel: "Seleccionar contexto",
  settings: "Configuración",
  language: "Idioma",
  gender: "Género",
  genderUnknown: "Desconocido",
  genderMale: "Masculino",
  genderFemale: "Femenino",
  emojiUsage: "Uso de emojis",
  emojiUsageNever: "Nunca",
  emojiUsageSometimes: "A veces",
  emojiUsageAlways: "Siempre",
  version: "Versión",
  releaseDate: "Fecha de lanzamiento",
  close: "Cerrar",
  clear: "Limpiar",
  explain: "Explicar",
  additionalContext: "Contexto adicional",
  recording: "Grabando...",
  idiomDetected: "Modismo detectado",
  copy: "Copiar",
  en: "Inglés (English)",
  ja: "Japonés (日本語)",
  ko: "Coreano (한국어)",
  "zh-CN": "Chino (simplificado) (简体中文)",
  "zh-TW": "Chino (tradicional) (繁體中文)",
  dk: "Danés (Dansk)",
  fr: "Francés (Français)",
  vi: "Vietnamita (Tiếng Việt)",
  sv: "Sueco (Svenska)",
  de: "Alemán (Deutsch)",
  es: "Español (Spanish)",
  ar: "Árabe (العربية)",
  bn: "Bengalí (বাংলা)",
  ru: "Ruso (Русский)",
  uk: "Ucraniano (Українська)",
  ceb: "Cebuano",
  tl: "Tagalo",
  it: "Italiano (Italian)",
  el: "Griego (Ελληνικά)",
  "ko (nk)": "Coreano, dialecto de Corea del Norte (조선말)",
  casual: "Informal",
  social_media: "Redes sociales",
};

export default es;