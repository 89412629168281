import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { TranslationFunction } from '../hooks/useTranslation';
import { SUPPORTED_LANGUAGES, GENDER_OPTIONS, EMOJI_USAGE_OPTIONS } from '../utils/constants';
import { logger } from '../utils/logger';
import { LanguageKey, GenderKey, EmojiUsageKey } from '../types';

interface SettingsPanelProps {
  open: boolean;
  onClose: () => void;
  t: TranslationFunction;
  currentLanguage: LanguageKey;
  onLanguageChange: (language: LanguageKey) => void;
  currentGender: GenderKey;
  onGenderChange: (gender: GenderKey) => void;
  currentEmojiUsage: EmojiUsageKey;
  onEmojiUsageChange: (emojiUsage: EmojiUsageKey) => void;
  version: string;
  releaseDate: string;
}

interface Language {
  code: string;
  name: string;
}

const LANGUAGE_STORAGE_KEY = 'preferredLanguage';

const SettingsPanel: React.FC<SettingsPanelProps> = ({
  open,
  onClose,
  t,
  currentLanguage,
  onLanguageChange,
  currentGender,
  onGenderChange,
  currentEmojiUsage,
  onEmojiUsageChange,
  version,
  releaseDate,
}) => {
  const handleLanguageChange = (newLanguage: LanguageKey) => {
    logger.debug('UI Language change requested', { from: currentLanguage, to: newLanguage });
    onLanguageChange(newLanguage);
    localStorage.setItem(LANGUAGE_STORAGE_KEY, newLanguage);
  };

  const handleGenderChange = (newGender: GenderKey) => {
    logger.debug('Gender change requested', { from: currentGender, to: newGender });
    onGenderChange(newGender);
  };

  const handleEmojiUsageChange = (newEmojiUsage: EmojiUsageKey) => {
    logger.debug('Emoji usage change requested', { from: currentEmojiUsage, to: newEmojiUsage });
    onEmojiUsageChange(newEmojiUsage);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('settings')}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel
            id="language-select-label"
            sx={{
              backgroundColor: 'white',
              padding: '0 4px',
              '&.Mui-focused': {
                color: 'primary.main',
              },
            }}
          >
            {t('language')}
          </InputLabel>
          <Select
            labelId="language-select-label"
            value={currentLanguage}
            onChange={(e) => handleLanguageChange(e.target.value as LanguageKey)}
          >
            {SUPPORTED_LANGUAGES.map((lang: Language) => (
              <MenuItem key={lang.code} value={lang.code}>
                {t(lang.code as any)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel
            id="gender-select-label"
            sx={{
              backgroundColor: 'white',
              padding: '0 4px',
              '&.Mui-focused': {
                color: 'primary.main',
              },
            }}
          >
            {t('gender')}
          </InputLabel>
          <Select
            labelId="gender-select-label"
            value={currentGender}
            onChange={(e) => handleGenderChange(e.target.value as GenderKey)}
          >
            {Object.entries(GENDER_OPTIONS).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {t(key as any)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel
            id="emoji-usage-select-label"
            sx={{
              backgroundColor: 'white',
              padding: '0 4px',
              '&.Mui-focused': {
                color: 'primary.main',
              },
            }}
          >
            {t('emojiUsage')}
          </InputLabel>
          <Select
            labelId="emoji-usage-select-label"
            value={currentEmojiUsage}
            onChange={(e) => handleEmojiUsageChange(e.target.value as EmojiUsageKey)}
          >
            {Object.entries(EMOJI_USAGE_OPTIONS).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {t(key as any)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {t('version')}: {version}
        </Typography>
        <Typography variant="body2">
          {t('releaseDate')}: {releaseDate}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsPanel;