import React from 'react';
import { IconButton, SxProps, Theme } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { logger } from '../utils/logger';

interface ImageUploaderProps {
  onImageUpload: (file: File) => Promise<void>;
  disabled: boolean;
  buttonStyle?: SxProps<Theme>;
}

/**
 * ImageUploader component handles image upload and processing.
 */
const ImageUploader: React.FC<ImageUploaderProps> = ({ onImageUpload, disabled, buttonStyle }) => {
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        await onImageUpload(file);
      } catch (error) {
        logger.error('Error uploading image:', error);
        // Handle the error (e.g., show an error message to the user)
      }
    }
  };

  const defaultStyle: SxProps<Theme> = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    borderRadius: '4px',
    padding: '8px',
    height: 40,
    width: 40,
    minWidth: 40,
    '&:hover': {
      backgroundColor: 'primary.dark',
    },
    '&.Mui-disabled': {
      backgroundColor: 'action.disabledBackground',
      color: 'action.disabled',
    },
  };

  return (
    <IconButton
      component="label"
      disabled={disabled}
      sx={{ ...defaultStyle, ...buttonStyle }}
    >
      <CameraAltIcon />
      <input
        type="file"
        hidden
        accept="image/*"
        onChange={handleFileChange}
      />
    </IconButton>
  );
};

export default ImageUploader;