import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { TranslationFunction } from '../hooks/useTranslation';

interface AppHeaderProps {
  onClear: () => void;
  onOpenSettings: () => void;
  t: TranslationFunction;
}

/**
 * AppHeader component displays the application header with a title, a clear button, and a settings button.
 */
const AppHeader: React.FC<AppHeaderProps> = ({ onClear, onOpenSettings, t }) => {
  return (
    <AppBar position="fixed" sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
    }}>
      <Toolbar>
        <img
          src="/icons/512.png"
          alt="Icon"
          style={{
            height: '100%',
            maxHeight: '48px',
            marginLeft: -12,
            marginRight: 5,
            padding: 5
          }}
        />
        <Typography variant="h5" sx={{ flexGrow: 1, fontFamily: 'Roboto, sans-serif' }}>
          {t('appTitle')}
        </Typography>
        <Button color="secondary" variant="contained" onClick={onClear} sx={{ mr: 1 }}>
          <Typography sx={{ fontFamily: 'Roboto, sans-serif' }}>
            {t('clear')}
          </Typography>
        </Button>
        <IconButton color="inherit" onClick={onOpenSettings} aria-label={t('settings')}>
          <SettingsIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;