// This file contains Cebuano translations

import { Translations } from '../types';

const ceb: Translations['ceb'] = {
  appTitle: "MongMong",
  uploadImage: "Pag-upload og litrato",
  selectLanguage: "Pagpili og pinulongan",
  translate: "Paghubad",
  sourceLanguage: "Gigikanan nga pinulongan",
  targetLanguage: "Target nga pinulongan",
  enterText: "Pagsulod og teksto nga ihubad",
  translationResults: "Resulta sa paghubad",
  cameraButtonLabel: "Pagkuha og litrato",
  microphoneButtonLabel: "Pagsugod og rekording",
  speakerButtonLabel: "Pagpatukar og audio",
  contextSelectorLabel: "Pagpili og konteksto",
  settings: "Mga setting",
  language: "Pinulongan",
  gender: "Sekso",
  genderUnknown: "Wala mahibaloan",
  genderMale: "Lalaki",
  genderFemale: "Babaye",
  emojiUsage: "Paggamit sa emoji",
  emojiUsageNever: "Dili gayud",
  emojiUsageSometimes: "Usahay",
  emojiUsageAlways: "Kanunay",
  version: "Bersyon",
  releaseDate: "Petsa sa pagrelease",
  close: "Sirado",
  clear: "Limpyo",
  explain: "Ipasabot",
  additionalContext: "Dugang nga konteksto",
  recording: "Nagrekord...",
  idiomDetected: "Nakit-an ang idyoma",
  copy: "Kopya",
  en: "Iningles (English)",
  ja: "Hapon (日本語)",
  ko: "Koreano (한국어)",
  "zh-CN": "Intsik (Simplified) (简体中文)",
  "zh-TW": "Intsik (Traditional) (繁體中文)",
  dk: "Danish (Dansk)",
  fr: "Pranses (Français)",
  vi: "Vietnamese (Tiếng Việt)",
  sv: "Swedish (Svenska)",
  de: "Aleman (Deutsch)",
  es: "Espanyol (Español)",
  ar: "Arabo (العربية)",
  bn: "Bengali (বাংলা)",
  ru: "Ruso (Русский)",
  uk: "Ukrainian (Українська)",
  ceb: "Cebuano",
  tl: "Tagalog",
  it: "Italyano (Italiano)",
  el: "Griyego (Ελληνικά)",
  "ko (nk)": "Koreano, North Korean dialect (조선말)",
  casual: "Kaswal",
  social_media: "Social media",
};

export default ceb;