import React from 'react';
import { Box, Typography, Paper, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SpeakerButton from '../SpeakerButton';
import { TranslationVariant } from '../types';
import { frequencyColorMap } from '../utils/constants';
import { TranslationFunction } from '../hooks/useTranslation';

interface TranslationResultsProps {
  translations: TranslationVariant[];
  onSpeak: (text: string, voice: string) => Promise<string>;
  isExplainMode: boolean;
  sourceText: string;
  t: TranslationFunction;
}

const TranslationResults: React.FC<TranslationResultsProps> = ({
  translations,
  onSpeak,
  isExplainMode,
  sourceText,
  t,
}) => {
  // Filter out any undefined or empty translations
  const validTranslations = translations.filter(item => item && item.translation);

  return (
    <Paper
      elevation={validTranslations.length > 0 ? 1 : 0}
      sx={{
        p: 2,
        my: 0.5,
      }}
    >
      {validTranslations.map((item, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
          <Box sx={{ flex: 1, flexDirection: 'column' }}>
            {item.frequencyRating && frequencyColorMap[item.frequencyRating] && (
              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: '0.8em', 
                  fontWeight: 'bold',
                  color: frequencyColorMap[item.frequencyRating], 
                  lineHeight: '1em',
                  marginTop: '0.5em',
                  marginBottom: '0.25em'
                }}
              >
                {item.frequencyRatingLocalized}
              </Typography>
            )}

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1em' }}>
              {item.translation}
            </Typography>

            {item.idiom && (
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 'bold',
                  fontSize: '0.75em',
                  fontFamily: "'Orbitron', sans-serif",
                  color: '#181e54',
                  mt: 0.1,
                  mb: 1.2,
                }}
              >
                {t('idiomDetected') as string}
              </Typography>
            )}

            {item.transliteration && (
              <Typography variant="body2" sx={{ fontSize: '0.9em', color: 'text.secondary', mt: 0.1, mb: 1.2 }}>
                {item.transliteration}
              </Typography>
            )}

            <Typography variant="body2" sx={{ fontSize: '0.8em', lineHeight: '1.2em', color: 'text.primary' }}>
              {item.explanation}
            </Typography>
          </Box>

          <SpeakerButton
            text={isExplainMode ? sourceText : item.translation}
            voice={item.recommendedVoice || 'alloy'}
            onSpeak={onSpeak}
          />

          <IconButton 
            onClick={() => navigator.clipboard.writeText(item.translation)} 
            aria-label={t('copy') as string} 
            size="small"
          >
            <ContentCopyIcon />
          </IconButton>
        </Box>
      ))}
    </Paper>
  );
};

export default TranslationResults;