import { Translations } from '../types';

const ru: Translations['ru'] = {
  appTitle: "МонгМонг",
  uploadImage: "Загрузить изображение",
  selectLanguage: "Выбрать язык",
  translate: "Перевести",
  sourceLanguage: "Исходный язык",
  targetLanguage: "Целевой язык",
  enterText: "Введите текст для перевода",
  translationResults: "Результаты перевода",
  cameraButtonLabel: "Сделать фото",
  microphoneButtonLabel: "Начать запись",
  speakerButtonLabel: "Воспроизвести аудио",
  contextSelectorLabel: "Выбрать контекст",
  settings: "Настройки",
  language: "Язык",
  gender: "Пол",
  genderUnknown: "Неизвестно",
  genderMale: "Мужской",
  genderFemale: "Женский",
  emojiUsage: "Использование эмодзи",
  emojiUsageNever: "Никогда",
  emojiUsageSometimes: "Иногда",
  emojiUsageAlways: "Всегда",
  version: "Версия",
  releaseDate: "Дата выпуска",
  close: "Закрыть",
  clear: "Очистить",
  explain: "Объяснить",
  additionalContext: "Дополнительный контекст",
  recording: "Идет запись...",
  idiomDetected: "Обнаружен идиоматический оборот",
  copy: "Копировать",
  en: "Английский (English)",
  ja: "Японский (日本語)",
  ko: "Корейский (한국어)",
  "zh-CN": "Китайский (упрощенный) (简体中文)",
  "zh-TW": "Китайский (традиционный) (繁體中文)",
  dk: "Датский (Dansk)",
  fr: "Французский (Français)",
  vi: "Вьетнамский (Tiếng Việt)",
  sv: "Шведский (Svenska)",
  de: "Немецкий (Deutsch)",
  es: "Испанский (Español)",
  ar: "Арабский (العربية)",
  bn: "Бенгальский (বাংলা)",
  ru: "Русский",
  uk: "Украинский (Українська)",
  ceb: "Себуанский",
  tl: "Тагальский",
  it: "Итальянский (Italiano)",
  el: "Греческий (Ελληνικά)",
  "ko (nk)": "Корейский, северокорейский диалект (조선말)",
  casual: "Неформальный",
  social_media: "Социальные сети",
};

export default ru;