// This file contains German translations

import { Translations } from '../types';

const de: Translations['de'] = {
  appTitle: "MongMong",
  uploadImage: "Bild hochladen",
  selectLanguage: "Sprache auswählen",
  translate: "Übersetzen",
  sourceLanguage: "Quellsprache",
  targetLanguage: "Zielsprache",
  enterText: "Text zum Übersetzen eingeben",
  translationResults: "Übersetzungsergebnisse",
  cameraButtonLabel: "Foto aufnehmen",
  microphoneButtonLabel: "Aufnahme starten",
  speakerButtonLabel: "Audio abspielen",
  contextSelectorLabel: "Kontext auswählen",
  settings: "Einstellungen",
  language: "Sprache",
  gender: "Geschlecht",
  genderUnknown: "Unbekannt",
  genderMale: "Männlich",
  genderFemale: "Weiblich",
  emojiUsage: "Emoji-Nutzung",
  emojiUsageNever: "Nie",
  emojiUsageSometimes: "Manchmal",
  emojiUsageAlways: "Immer",
  version: "Version",
  releaseDate: "Veröffentlichungsdatum",
  close: "Schließen",
  clear: "Löschen",
  explain: "Erklären",
  additionalContext: "Zusätzlicher Kontext",
  recording: "Aufnahme läuft...",
  idiomDetected: "Redewendung erkannt",
  copy: "Kopieren",
  en: "Englisch (English)",
  ja: "Japanisch (日本語)",
  ko: "Koreanisch (한국어)",
  "zh-CN": "Chinesisch (vereinfacht) (简体中文)",
  "zh-TW": "Chinesisch (traditionell) (繁體中文)",
  dk: "Dänisch (Dansk)",
  fr: "Französisch (Français)",
  vi: "Vietnamesisch (Tiếng Việt)",
  sv: "Schwedisch (Svenska)",
  de: "Deutsch",
  es: "Spanisch (Español)",
  ar: "Arabisch (العربية)",
  bn: "Bengalisch (বাংলা)",
  ru: "Russisch (Русский)",
  uk: "Ukrainisch (Українська)",
  ceb: "Cebuano",
  tl: "Tagalog",
  it: "Italienisch (Italiano)",
  el: "Griechisch (Ελληνικά)",
  "ko (nk)": "Koreanisch, nordkoreanischer Dialekt (조선말)",
  casual: "Informell",
  social_media: "Soziale Medien",
};

export default de;