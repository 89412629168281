import { Translations } from '../types';

const ja: Translations['ja'] = {
  appTitle: "モンモン",
  uploadImage: "画像をアップロード",
  selectLanguage: "言語を選択",
  translate: "翻訳",
  sourceLanguage: "原語",
  targetLanguage: "対象言語",
  enterText: "翻訳するテキストを入力",
  translationResults: "翻訳結果",
  cameraButtonLabel: "写真を撮る",
  microphoneButtonLabel: "録音開始",
  speakerButtonLabel: "音声を再生",
  contextSelectorLabel: "コンテキストを選択",
  settings: "設定",
  language: "言語",
  gender: "性別",
  genderUnknown: "不明",
  genderMale: "男性",
  genderFemale: "女性",
  emojiUsage: "絵文字の使用",
  emojiUsageNever: "使用しない",
  emojiUsageSometimes: "時々使用する",
  emojiUsageAlways: "常に使用する",
  version: "バージョン",
  releaseDate: "リリース日",
  close: "閉じる",
  clear: "クリア",
  explain: "説明",
  additionalContext: "追加コンテキスト",
  recording: "録音中...",
  idiomDetected: "慣用句が検出されました",
  copy: "コピー",
  en: "英語 (English)",
  ja: "日本語 (Japanese)",
  ko: "韓国語 (한국어)",
  "zh-CN": "中国語（簡体字） (简体中文)",
  "zh-TW": "中国語（繁体字） (繁體中文)",
  dk: "デンマーク語 (Dansk)",
  fr: "フランス語 (Français)",
  vi: "ベトナム語 (Tiếng Việt)",
  sv: "スウェーデン語 (Svenska)",
  de: "ドイツ語 (Deutsch)",
  es: "スペイン語 (Español)",
  ar: "アラビア語 (العربية)",
  bn: "ベンガル語 (বাংলা)",
  ru: "ロシア語 (Русский)",
  uk: "ウクライナ語 (Українська)",
  ceb: "セブアノ語",
  tl: "タガログ語",
  it: "イタリア語 (Italiano)",
  el: "ギリシャ語 (Ελληνικά)",
  "ko (nk)": "韓国語（北朝鮮方言） (조선말)",
  casual: "カジュアル",
  social_media: "ソーシャルメディア",
};

export default ja;