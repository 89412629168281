import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import CircularProgress from '@mui/material/CircularProgress';
import { logger } from './utils/logger';

interface SpeakerButtonProps {
  text: string;
  voice: string;
  onSpeak: (text: string, voice: string) => Promise<string>;
}

const SpeakerButton: React.FC<SpeakerButtonProps> = ({ text, voice, onSpeak }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = async () => {
    setIsPlaying(true);
    try {
      const audioUrl = await onSpeak(text, voice);
      const audio = new Audio(audioUrl);
      audio.onended = () => setIsPlaying(false);
      audio.play();
    } catch (error) {
      logger.error('Error playing audio:', error);
      setIsPlaying(false);
    }
  };

  return (
    <IconButton onClick={handleClick} disabled={isPlaying} size="small">
      {isPlaying ? <CircularProgress size={24} /> : <VolumeUpIcon />}
    </IconButton>
  );
};

export default SpeakerButton;