import { OpenAI } from 'openai';
import { TranslationOutput, ExplanationOutput, FetchDataArgs, FetchExplanationArgs, AIMessage } from './types/ai';
import { logger } from './utils/logger';
import { OPENAI_API_KEY } from './utils/constants';

const configuration = {
    apiKey: OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
}

const openai = new OpenAI(configuration);

export async function generateSpeech(text: string, voice: 'alloy' | 'echo' | 'fable' | 'onyx' | 'nova' | 'shimmer' = 'alloy'): Promise<string> {
    try {
    const response = await openai.audio.speech.create({
      model: 'tts-1-hd',
      input: text,
      voice: voice,
    });

    const audioBlob = new Blob([await response.arrayBuffer()], { type: 'audio/mpeg' });
    return URL.createObjectURL(audioBlob);
  } catch (error) {
    logger.error('Error generating speech:', error);
    throw error;
  }
}

export class RemoteAITranslate {
    private state: TranslationOutput[] = [];
    private updateResolvers: Array<(value: TranslationOutput | null) => void> = [];
    private hasCompleted = false;
    private mustEmit = false;

    getData(i = 0): TranslationOutput[] {
        return this.state.slice(i);
    }

    async awaitNextUpdate(): Promise<TranslationOutput | null> {
        if (this.hasCompleted) {
            return null;
        }
        return new Promise((resolve) => {
            this.updateResolvers.push(resolve);
        });
    }

    private emitData(data: TranslationOutput): void {
        this.state.push(data);
        this.updateResolvers.forEach(resolve => resolve(data));
        this.updateResolvers = [];
    }

    private emitTranslationOption(args: TranslationOutput): boolean {
        logger.debug('Emitting translation option:', args);
        const updatedArgs = {
            ...args,
            frequency: {
                rating: args.frequencyRating,
                localized: args.frequencyRatingLocalized
            }
        };
        this.emitData(updatedArgs);
        this.mustEmit = true;
        return true;
    }

    private emitComplete(): void {
        this.hasCompleted = true;
        this.updateResolvers.forEach(resolve => resolve(null));
        this.updateResolvers = [];
    }

    async *fetchData(args: FetchDataArgs): AsyncGenerator<{ chunk: string }, void, unknown> {
        const {
            prompt,
            sourceLang = 'EN/english',
            targetLang = 'KO/korean',
            numTranslations = 5,
            numOffset = 0,
            modelName = "gpt-4o",
            additionalContext,
        } = args;

        if (numTranslations < 1) { return }
        const messages: AIMessage[] = [
            {
                role: "system",
                content: `You are an endpoint for a translation API which will be used by a law enforcement agency who is advising a movie producer to understand conversations for important movies and tv shows that involve many real-world scenarios.`
            },
            {
                role: "system",
                content: `Source language is ${sourceLang}, and target language is: ${targetLang}. Please provide ${numTranslations} alternatives, which are all different, each as their own object response.`
            },
            {
                role: "system",
                content: `Each object should have a num property, sequentially, but start with ${numOffset}. Include the TRANSLATION, EXPLANATION, PRONUNCIATION, FREQUENCY_RATING, FREQUENCY_RATING_LOCALIZED, and RECOMMENDED_VOICE fields always. Keep the REOCMMENDED_VOICE the same for all outputted translations options.`
            },
            { role: "system", content: `Please translate the entire user input; don't stop or provide partial translation in the case of a new line, still translate everything.`},
            {
                role: "system",
                content: `Return the following information. 
NUM: (number) A sequential number of the translation, starting with ${numOffset}

TRANSLATION: (${targetLang} string) the directly translated text into ${targetLang} (based on the given context and the user input)

FREQUENCY_RATING: (English string) rating of how common the words used in this sentence appear ordinarily. Proper values are for 'rating' are 'daily', 'common', 'uncommon', 'rare'. This is not how common the sentence content is in every day life, but rather how common are the word choices or idioms used in the translation.

FREQUENCY_RATING_LOCALIZED: (${sourceLang} string) The value of FREQUENCY_RATING translated into ${sourceLang}. MUST BE ${sourceLang}

PRONUNCIATION: (${sourceLang} string) 100% in ${sourceLang} alphabets or scripts to provide the user with the proper pronunciation of the translated text. This should be a phoenetic approximation given that the script used is a foreign language relative to the translation. DO NOT INCLUDE the original translation, just the pronunciation. ` + 

(sourceLang != "EN/english" ? ` Do not use ENGLISH/roman script or ${targetLang} writing here whatsoever, choose a ${sourceLang} script. As the goal is to help a ${sourceLang} speaker properly pronounce the translation.` : "This should be a transliteration basically.") + 
`. For example, the translation string 화장실을 찾고 있습니다 if translated from Japanese would have the pronunciation "ハジャンシルル チャッコ イッスムニダ-."

EXPLANATION: (${sourceLang} string) An explanation in ${sourceLang} of the nuance and detail of the translation; please reference snippets of the translation to provide specific detailed explanation, and always include a parenthetical after a reference on a snippet to include a pronunciation in the ${sourceLang} script within the explanation text. When the translation is literal and straightforward, please create a more brief explanation. Use a longer explanation when there is more nuance necessary to understand the translation. Always first check for idioms, with a VERY slight bias to find them, because these are the hardest to understand. In the case that the entire text is an idiom, then first try to find another matching idiom in the target language as the translation. If not, then translate and explain literally, and provide a historical and present-day nuanced and accurate explanation. If the idiom is part of a bigger sentence, do your best to provide a translation and then explain the idiom carefully in the bigger sentence

RECOMMENDED_VOICE: (string) Based on the context and content of the translation, recommend a voice for text-to-speech. Choose from  'onyx' (male) or 'nova' (female). If the speaker gender is clearly specified in the context, then cuse that. If it's not, then see if the content makes it clear. If it's totally unclear, then choose 'alloy' (neutral). The RECOMMENDED_VOICE should be the same for all outputted translations options.

The specific format should be each result parameter in the specific order given below, and each parameter should be followed by the four characters "||||" and a newline. Absolutely ensure that there is no space in that delimiter. "||||\\n" is the whole delimiter. To be clear, don't send back the all caps label of the parameter, just send the parameter format. For clarity in the below format you'll never send the string "NUM" but rahter since the actual index number as an integer. Here is the format:
NUM||||
TRANSLATION||||
FREQUENCY_RATING||||
FREQUENCY_RATING_LOCALIZED||||
PRONUNCIATION||||
EXPLANATION||||
RECOMMENDED_VOICE||||


Here is an example of a proper output, which doesn't include field labels:
0||||
こんにちは、お元気ですか？||||
daily||||
daily|||| 
Kon'nichiwa, ogenki desu ka?|||| 
This is a standard greeting equivalent to "Hello, how are you?" in English. It is polite and commonly used. ("こんにちは、お元気ですか？", Kon'nichiwa, ogenki desu ka?)||||
nova||||

`
            },
            {
                role: "system",
                content: `Regarding emoji usage: The user has set a preference for emoji usage in translations. This preference should be applied as follows:
                - If set to "Always", include appropriate emojis in all translations.
                - If set to "Never", do not include any emojis in the translations.
                - If set to "Sometimes", use emojis selectively and when appropriate to enhance the meaning or emotion of the translation.
                Be sure to consider this preference when crafting your translations and explanations.`
            }
        ];
        if (additionalContext) {
            messages.push({ role: "system", content: `There is some more context about the original text which may be important to the speaker's situation. This additional context is the most important guidance in crafting your response, and in choosing the RECOMMENDED_VOICE: ${additionalContext}` });
        }
        messages.push({ role: "user", content: "Here is the content:\n" + prompt });

        logger.logAIPrompt(messages);

        try {
            const aStream = await openai.beta.chat.completions.stream({
                model: modelName,
                messages: messages,
                stream: true
            });

            const mapper = (index: number, output: Partial<TranslationOutput>, value: string): void => {
                const cleanValue = value.trim();
                switch (index) {
                    case 0:
                        output.num = parseInt(cleanValue);
                        break;
                    case 1:
                        output.translation = cleanValue;
                        break;
                    case 2:
                        output.frequencyRating = cleanValue;
                        break;
                    case 3:
                        output.frequencyRatingLocalized = cleanValue;
                        break;
                    case 4:
                        output.transliteration = cleanValue;
                        break;
                    case 5:
                        output.explanation = cleanValue;
                        break;
                    case 6:
                        output.recommendedVoice = cleanValue;
                        break;
                }
            }

            let buffer = "";
            let index = 0;
            let lastMatchIndex = 0;
            let currentOutput: Partial<TranslationOutput> = {}; 
            this.state.push(currentOutput as TranslationOutput);
            let fullResponse = "";

            for await (const chunk of aStream) {
                const content = chunk.choices[0]?.delta.content || '';
                buffer += content;
                fullResponse += content;
                yield { chunk: content };

                const pattern = /(?:NUM\|\|\|\||TRANSLATION\|\|\|\||FREQUENCY_RATING\|\|\|\||FREQUENCY_RATING_LOCALIZED\|\|\|\||TRANSLITERATION\|\|\|\||EXPLANATION\|\|\|\||RECOMMENDED_VOICE\|\|\|\|)?(.*?)\|\|\|\|/g;
                let match;
                while ((match = pattern.exec(buffer)) !== null) {
                    const value = match[1].trim();
                    if (["NUM", "TRANSLATION", "FREQUENCY_RATING", "FREQUENCY_RATING_LOCALIZED", "TRANSLITERATION", "EXPLANATION", "RECOMMENDED_VOICE"].includes(value)) continue;
                    mapper(index % 7, currentOutput, value);
                    if (index % 7 === 6) {
                        logger.logParsedResult(currentOutput);
                        currentOutput = {};
                        this.state.push(currentOutput as TranslationOutput);
                    }
                    index++;
                    lastMatchIndex = pattern.lastIndex;
                }
                buffer = buffer.substring(lastMatchIndex);
                lastMatchIndex = 0;
            }
            if (index % 7 !== 0 && buffer.length > 0) {
                mapper(index % 7, currentOutput, buffer.replace(/\|+$/, ''));
            }

            logger.logAIResponse(fullResponse);
            logger.logParsedResult(this.state);

        } catch (error) {
            logger.error('Error querying GPT:', error);
            throw error;
        }
    }
}

export class RemoteAIExplain {
    private oAI: OpenAI;
    private output: ExplanationOutput;

    constructor() {
        this.output = {
            num: 0,
            translation: "",
            frequencyRating: "",
            frequencyRatingLocalized: "",
            transliteration: "",
            explanation: "",
            idiom: "",
            recommendedVoice: "alloy"
        };
        this.oAI = new OpenAI({
            apiKey: OPENAI_API_KEY,
            dangerouslyAllowBrowser: true,
        });
    }

    getData(): ExplanationOutput {
        return this.output;
    }

    async *fetchExplanation(args: FetchExplanationArgs): AsyncGenerator<ExplanationOutput, void, unknown> {
        const {
            inputText,
            additionalContext,
            sourceLang,
            targetLang,
        } = args;

        const messages: AIMessage[] = [
            {
                role: "system",
                content: `You are an endpoint for a translation API which is used for nuanced explanations. Provide a detailed translation with an emphasis on explaining cultural and linguistic nuances. Please translate from ${sourceLang} to ${targetLang} such that both the translation and the explanation are in ${targetLang}.`
            },
            {
                role: "system",
                content: `Please also provide a frequency property as an object with the values 'rating' and 'localized', which rates the translation on how common the words used in this sentence appear ordinarily. Proper values are for 'rating' are 'daily', 'common', 'uncommon', 'rare', and 'localized' should translate the value into the source Language ${sourceLang} This is not how common the sentence content is, but rather the word choices or idioms used in the translation. `
            },
            {
                role: "system",
                content: `include a "transliteration" property which is a phonetic spelling of the output translated text of each alternative using the script of ${targetLang}. This should be a literal, one-to-one mapping of an alternate phonetic spelling of the outputted translation. For instance, in explaining "안녕" from Korean to English, the "translation" might be "Hello" and the "transliteration" would be "An yong". Note the "transliteration" is ALWAYS in the alphabet of the target language ${targetLang}. "transliteration" must ALWAYS be present.`
            },
            {
                role: "system",
                content: `Return the following information. 
    NUM: (number) A sequential number of the translation, starting with 1

    TRANSLATION: (string) the directly translated text into ${targetLang} (based on the given context and the user input )

    FREQUENCY_RATING: (string) rating of how common the words used in this sentence appear ordinarily. Proper values are for 'rating' are 'daily', 'common', 'uncommon', 'rare'

    FREQUENCY_RATING_LOCALIZED: (string) The value of FREQUENCY_RATING translated into ${targetLang}

    TRANSLITERATION: the phonetic spelling of the user content provided using the script of ${targetLang} (if user content is in ${targetLang} then just use an empty string for the value here). This should be a literal, one-to-one mapping of an alternate phonetic spelling of the outputted translation. For instance, in explaining "안녕" from Korean to English, the TRANSLATION might be "Hello" and the TRANSLITERATION would be "An yong". Note the TRANSLITERATION is ALWAYS in the alphabet of the target language ${targetLang}. 

    EXPLANATION: (string) In the explanation, please reference snippets of the original any script of ${sourceLang} to provide specific detailed explanation, and always include a parenthetical after it with the transliteration into ${targetLang} within the explanation text. When the translation is literal and straightforward, please create a more brief explanation. Use a longer explanation when there is more nuance necessary to understand the translation. Always first check for idioms, with a VERY slight bias to find them, because these are the hardest to understand. In the case that the entire text is an idiom, then first try to find another matching idiom in the target langauge as the translation. If not, then translate and explain literally, and provide a historical and present-day nuanced and accurate explanation. If the idiom is part of a bigger sentence, do your best to provide a translation and then explain the idiom carefully in the bigger sentence

    IDIOM: the phrase 'Idiom detected' translated into ${targetLang} if an idiom is detected, otherwise empty string.

    RECOMMENDED_VOICE: (string) Based on the context and content of the translation, recommend a voice for text-to-speech. Choose from 'alloy' (neutral), 'echo' (male), 'fable' (female), 'onyx' (male), 'nova' (female), or 'shimmer' (female). Consider the speaker's gender if mentioned in the context, or infer from the content if possible.

    The specific format should be each result parameter in the specific order given below, and each parameter should be followed by the four characters "||||" and a newline. Absolutely ensure that there is no space in that delimiter. "||||\\n" is the whole delimiter  To be clear, don't send back the all caps label of the parameter, just send the parameter format. Here is the format:
NUM||||
TRANSLATION||||
IDIOM||||
FREQUENCY_RATING||||
FREQUENCY_RATING_LOCALIZED||||
TRANSLITERATION|||
EXPLANATION||||
RECOMMENDED_VOICE||||


So for example if explaining an english term in english:
1||||
How are you?||||
Idiom Detected||||
common||||
common||||
How are you?||||
This is a common greeting, which may be answering literally, or it can be treated as a simple hello||||
alloy||||
`
            },
            {
                role: "system",
                content: "If there is some error or commentary you need to display, then return the desired format and put the error message in the translation or explanation property, or both as desired."
            },
            {
                role: "system",
                content: `Please translate the entire user input; don't stop or provide partial translation in the case of a new line, still translate everything.`
            },
            {
                role: "system",
                content: `Regarding emoji usage: The user has set a preference for emoji usage in translations. This preference should be applied as follows:
                - If set to "Always", include appropriate emojis in all translations and explanations.
                - If set to "Never", do not include any emojis in the translations or explanations.
                - If set to "Sometimes", use emojis selectively and when appropriate to enhance the meaning or emotion of the translation and explanation.
                Be sure to consider this preference when crafting your translations and explanations.`
            }
        ];
        if (additionalContext) {
            messages.push({ role: "system", content: `There is some more context about the original text which may be important to the speaker's situation. This additional context is the most important guidance in crafting your response: ${additionalContext}` })
        }
        messages.push(
            { role: "user", content: inputText }
        );

        logger.logAIPrompt(messages);

        const aStream = await this.oAI.beta.chat.completions.stream({
            model: "gpt-4o",
            messages: messages,
            stream: true
        });

        const mapper = (index: number, output: ExplanationOutput, value: string): void => {
            const cleanValue = value.replace(/^(NUM|TRANSLATION|FREQUENCY_RATING|FREQUENCY_RATING_LOCALIZED|TRANSLITERATION|EXPLANATION|RECOMMENDED_VOICE)\|\|\|\|/, '').trim();
            
            switch (index) {
                case 0:
                    output.num = parseInt(cleanValue);
                    break;
                case 1:
                    output.translation = cleanValue;
                    break;
                case 2:
                    output.idiom = cleanValue;
                    break;
                case 3:
                    output.frequencyRating = cleanValue;
                    break;
                case 4:
                    output.frequencyRatingLocalized = cleanValue;
                    break;
                case 5:
                    output.transliteration = cleanValue;
                    break;
                case 6:
                    output.explanation = cleanValue;
                    break;
                case 7:
                    output.recommendedVoice = cleanValue;
                    break;
            }
        }

        let buffer = "";
        let index = 0;
        let lastMatchIndex = 0;
        let fullResponse = "";

        for await (const chunk of aStream) {
            buffer += chunk.choices[0]?.delta.content || '';
            fullResponse += chunk.choices[0]?.delta.content || '';

            const pattern = /(.*?)\|\|\|\|/g;
            let match;
            while ((match = pattern.exec(buffer)) !== null) {
                const value = match[1].trim();
                mapper(index % 8, this.output, value);
                index++;
                lastMatchIndex = pattern.lastIndex;
            }
            buffer = buffer.substring(lastMatchIndex);
            lastMatchIndex = 0;

            if (index <= 7 && buffer.length > 0) {
                mapper(index % 8, this.output, buffer.replace(/\|+$/, ''))
            }
            yield this.output;
        }
        if (index <= 7 && buffer.length > 0) {
            mapper(index % 8, this.output, buffer.replace(/\|+$/, ''))
        }
        logger.logAIResponse(fullResponse);
        logger.logParsedResult(this.output);
        yield this.output
    }
}

interface FetchImageExplanationArgs {
    imageContent: string;
    additionalContext?: string;
    sourceLang: string;
    targetLang: string;
}

export class RemoteAIImageExplain {
    private oAI: OpenAI;
    private output: ExplanationOutput;

    constructor() {
        this.output = {
            num: 0,
            translation: "",
            frequencyRating: "",
            frequencyRatingLocalized: "",
            transliteration: "",
            explanation: "",
            idiom: "",
            recommendedVoice: "alloy"
        };
        this.oAI = new OpenAI({
            apiKey: OPENAI_API_KEY,
            dangerouslyAllowBrowser: true,
        });
    }

    getData(): ExplanationOutput {
        return this.output;
    }

    async fetchImageExplanation(args: FetchImageExplanationArgs): Promise<ExplanationOutput> {
        const {
            imageContent,
            additionalContext,
            sourceLang,
            targetLang,
        } = args;

        const messages: AIMessage[] = [
            {
                role: "system",
                content: `You are an endpoint for an image analysis and translation API. Your task is to analyze images, translate any text found, and provide detailed explanations. The output language is ${targetLang}. `
            },
            {
                role: "system",
                content: `Analyze the image and provide the following:
1. A one-sentence description of the image.
2. Identify and translate any text visible in the image to ${targetLang}.
3. A detailed explanation of the image content, including any cultural or contextual nuances.`
            },
            {
                role: "system",
                content: `Include a frequency property with 'rating' and 'localized' values for any translated text. Rate how common the words used appear ordinarily. Values for 'rating' are 'daily', 'common', 'uncommon', 'rare'. The 'localized' value should be the rating translated into ${targetLang}.`
            },
            {
                role: "system",
                content: `Include a "transliteration" property as an empty string.`
            },
            {
                role: "system",
                content: `Return the following information:
NUM: (number) This should be the number 1
TRANSLATION: (string) This is a summary only of any text found in the image translated to ${targetLang}. If no text is found, use "No text detected in the image". If a mix of languages are detectd including ${targetLang} then still translate all coherently together to ${targetLang} and summarized
FREQUENCY_RATING: (string) Rating of how common the words used in the translation appear ordinarily. If no text is found, use "N/A".
FREQUENCY_RATING_LOCALIZED: (string) The FREQUENCY_RATING translated into ${targetLang}. If no text is found, use "N/A".
TRANSLITERATION: (string) Phonetic spelling of the translated text using the script of ${targetLang}. If no text is found, use "N/A".
EXPLANATION: (string) Start with a one-sentence description of the image. Then provide a detailed explanation of the image content, any text found translated to ${targetLang}, and cultural or contextual nuances. If text is found, reference snippets of the non-${targetLang} text with transliterations in parentheses.
IDIOM: (string) The phrase 'Idiom detected' translated into ${targetLang} if an idiom is detected in any text, otherwise an empty string.
RECOMMENDED_VOICE: (string) Recommend a voice for text-to-speech based on the content. Choose from 'alloy' (neutral), 'echo' (male), 'fable' (female), 'onyx' (male), 'nova' (female), or 'shimmer' (female).

Use the following format, with each parameter followed by "||||" and a newline:
NUM||||
TRANSLATION||||
IDIOM||||
FREQUENCY_RATING||||
FREQUENCY_RATING_LOCALIZED||||
TRANSLITERATION||||
EXPLANATION||||
RECOMMENDED_VOICE||||


Note will be no literal labels like "NUM" or "TRANSLATION" present whatsoever, just the data and the delimiter. Please follow this format EXACTLY. That means there should only be |||| appearing 8 times exactly. So for example if explaining an image with some korean writing in english:
1||||
How are you?||||
Idiom Detected||||
common||||
common||||
||||
The image depicts a sign in korean that says "안녕하세요" which is a common greeting meeting hello or how are you? This is a common greeting, which may be answering literally, or it can be treated as a simple hello||||
alloy||||


`
            },
            {
                role: "system",
                content: "If there is an error or commentary you need to display, include it in the TRANSLATION or EXPLANATION property, or both as appropriate."
            },
            {
                role: "system",
                content: `Regarding emoji usage: The user has set a preference for emoji usage in translations and explanations. This preference should be applied as follows:
                - If set to "Always", include appropriate emojis in all translations and explanations.
                - If set to "Never", do not include any emojis in the translations or explanations.
                - If set to "Sometimes", use emojis selectively and when appropriate to enhance the meaning or emotion of the translation and explanation.
                Be sure to consider this preference when crafting your translations and explanations.`
            }
        ];

        if (additionalContext) {
            messages.push({ role: "system", content: `Additional context about the image which may be important: ${additionalContext}` });
        }

        messages.push(
            { role: "user", content: [
                { type: "text", text: "Please analyze and explain this image:" },
                { type: "image_url", image_url: { url: `data:image/jpeg;base64,${imageContent}` } }
            ]}
        );

        logger.debug('RemoteAIImageExplain.fetchImageExplanation called', {
            sourceLang,
            targetLang,
            additionalContext,
            imageContentLength: imageContent.length
        });

        try {
            const response = await this.oAI.chat.completions.create({
                model: "gpt-4o",
                messages: messages,
                max_tokens: 4096,
            });

            const content = response.choices[0].message.content;
            logger.debug('Raw response from GPT:', content);

            if (content) {
                const sections = content.split('||||');
                const fields = ['NUM', 'TRANSLATION', 'IDIOM', 'FREQUENCY_RATING', 'FREQUENCY_RATING_LOCALIZED', 'TRANSLITERATION', 'EXPLANATION', 'RECOMMENDED_VOICE'];
                
                sections.forEach((section, index) => {
                    if (index < fields.length) {
                        const field = fields[index];
                        const value = section.replace(`${field}\n`, '').trim();
                        switch (field) {
                            case 'NUM':
                                this.output.num = parseInt(value) || 0;
                                break;
                            case 'TRANSLATION':
                                this.output.translation = value;
                                break;
                            case 'IDIOM':
                                this.output.idiom = value;
                                break;
                            case 'FREQUENCY_RATING':
                                this.output.frequencyRating = value;
                                break;
                            case 'FREQUENCY_RATING_LOCALIZED':
                                this.output.frequencyRatingLocalized = value;
                                break;
                            case 'TRANSLITERATION':
                                this.output.transliteration = value;
                                break;
                            case 'EXPLANATION':
                                this.output.explanation = value;
                                break;
                            case 'RECOMMENDED_VOICE':
                                this.output.recommendedVoice = value;
                                break;
                        }
                    }
                });
            }

            logger.debug('Parsed output:', this.output);

            // If no text was detected, use the explanation as the translation
            if (this.output.translation === "No text detected in the image") {
                this.output.translation = this.output.explanation;
            }

            return this.output;
        } catch (error) {
            logger.error('Error in RemoteAIImageExplain.fetchImageExplanation:', error);
            throw error;
        }
    }
}
