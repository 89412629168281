// This file contains Danish translations

import { Translations } from '../types';

const dk: Translations['dk'] = {
  appTitle: "MongMong",
  uploadImage: "Upload billede",
  selectLanguage: "Vælg sprog",
  translate: "Oversæt",
  sourceLanguage: "Kildesprog",
  targetLanguage: "Målsprog",
  enterText: "Indtast tekst til oversættelse",
  translationResults: "Oversættelsesresultater",
  cameraButtonLabel: "Tag billede",
  microphoneButtonLabel: "Start optagelse",
  speakerButtonLabel: "Afspil lyd",
  contextSelectorLabel: "Vælg kontekst",
  settings: "Indstillinger",
  language: "Sprog",
  gender: "Køn",
  genderUnknown: "Ukendt",
  genderMale: "Mand",
  genderFemale: "Kvinde",
  emojiUsage: "Brug af emojis",
  emojiUsageNever: "Aldrig",
  emojiUsageSometimes: "Nogle gange",
  emojiUsageAlways: "Altid",
  version: "Version",
  releaseDate: "Udgivelsesdato",
  close: "Luk",
  clear: "Ryd",
  explain: "Forklar",
  additionalContext: "Yderligere kontekst",
  recording: "Optager...",
  idiomDetected: "Idiom registreret",
  copy: "Kopier",
  en: "Engelsk (English)",
  ja: "Japansk (日本語)",
  ko: "Koreansk (한국어)",
  "zh-CN": "Kinesisk (forenklet) (简体中文)",
  "zh-TW": "Kinesisk (traditionel) (繁體中文)",
  dk: "Dansk",
  fr: "Fransk (Français)",
  vi: "Vietnamesisk (Tiếng Việt)",
  sv: "Svensk (Svenska)",
  de: "Tysk (Deutsch)",
  es: "Spansk (Español)",
  ar: "Arabisk (العربية)",
  bn: "Bengalsk (বাংলা)",
  ru: "Russisk (Русский)",
  uk: "Ukrainsk (Українська)",
  ceb: "Cebuano",
  tl: "Tagalog",
  it: "Italiensk (Italiano)",
  el: "Græsk (Ελληνικά)",
  "ko (nk)": "Koreansk, Nordkoreansk dialekt (조선말)",
  casual: "Afslappet",
  social_media: "Sociale medier",
};

export default dk;