import React, { useState, useRef, useEffect } from 'react';
import { OPENAI_API_KEY } from '../utils/constants';
import { logger, LogLevel } from '../utils/logger';
import { Button } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';

interface MicrophoneButtonProps {
  onSpeechToText: (text: string) => void;
  sourceLanguage: string;
  setIsRecording: (isRecording: boolean) => void;
  buttonStyle: React.CSSProperties;
  disabled?: boolean;
}

type AudioContextType = typeof AudioContext;

interface WindowWithWebkitAudioContext extends Window {
  webkitAudioContext: AudioContextType;
}

function hasWebkitAudioContext(window: Window): window is WindowWithWebkitAudioContext {
  return 'webkitAudioContext' in window;
}

const MicrophoneButton: React.FC<MicrophoneButtonProps> = ({ onSpeechToText, sourceLanguage, setIsRecording, buttonStyle, disabled }) => {
  const [isRecording, setIsRecordingLocal] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const audioContextRef = useRef<AudioContext | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const isDebugMode = logger['shouldLog'](LogLevel.DEBUG);

  useEffect(() => {
    debugLog('MicrophoneButton component mounted');
    return () => {
      debugLog('MicrophoneButton component unmounting');
      stopRecording();
    };
  }, []);

  const debugLog = (message: string) => {
    if (isDebugMode) {
      logger.debug(`[MicrophoneButton] ${message}`);
    }
  };

  const checkSecureContext = (): boolean => {
    const isSecure = window.isSecureContext;
    debugLog(`Secure context check: ${isSecure}`);
    if (!isSecure) {
      logger.error('Microphone access requires a secure context (HTTPS). Please use HTTPS or localhost.');
      return false;
    }
    return true;
  };

  const checkMicrophoneSupport = (): boolean => {
    const isSupported = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    debugLog(`Microphone support check: ${isSupported}`);
    if (!isSupported) {
      logger.error('Your browser does not support microphone access. Please try using a different browser or device.');
      return false;
    }
    return true;
  };

  const isIOS = (): boolean => {
    const ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !('MSStream' in window);
    debugLog(`iOS device detected: ${ios}`);
    return ios;
  };

  const getSupportedMimeType = (ios: boolean): string => {
    const types = ios ? ['audio/mp4'] : ['audio/webm', 'audio/mp4', 'audio/ogg'];
    for (const type of types) {
      if (MediaRecorder.isTypeSupported(type)) {
        debugLog(`Supported MIME type found: ${type}`);
        return type;
      }
    }
    debugLog('No supported MIME types found');
    return '';
  };

  const startRecording = async () => {
    debugLog('Starting recording process');
    chunksRef.current = [];

    if (!checkSecureContext() || !checkMicrophoneSupport()) {
      debugLog('Recording start aborted due to security or support issues');
      return;
    }

    const ios = isIOS();

    try {
      debugLog('Requesting microphone access...');
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      debugLog('Audio stream obtained');
      streamRef.current = stream;

      let AudioContextClass: AudioContextType;
      if (hasWebkitAudioContext(window)) {
        AudioContextClass = window.webkitAudioContext;
      } else if ('AudioContext' in window) {
        AudioContextClass = window.AudioContext;
      } else {
        throw new Error('AudioContext is not supported in this browser');
      }

      audioContextRef.current = new AudioContextClass();
      debugLog(`AudioContext created: ${audioContextRef.current.state}`);
      const source = audioContextRef.current.createMediaStreamSource(stream);
      debugLog('MediaStreamSource created');

      if (!ios) {
        debugLog('Attempting to add silence detector worklet');
        await audioContextRef.current.audioWorklet.addModule('silence-detector-worklet.js');
        const silenceDetector = new AudioWorkletNode(audioContextRef.current, 'silence-detector');
        source.connect(silenceDetector).connect(audioContextRef.current.destination);
        debugLog('Silence detector worklet added and connected');

        silenceDetector.port.onmessage = (event) => {
          debugLog(`Silence detector message received: ${JSON.stringify(event.data)}`);
          if (event.data.silence) {
            debugLog('Silence detected, stopping recording');
            stopRecording();
          }
        };
      }

      const mimeType = getSupportedMimeType(ios);
      if (!mimeType) {
        throw new Error('No supported MIME types found for this browser.');
      }

      debugLog(`Initializing MediaRecorder with MIME type: ${mimeType}`);
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });
      debugLog(`MediaRecorder state after initialization: ${mediaRecorderRef.current.state}`);

      mediaRecorderRef.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data);
          debugLog(`Received audio chunk: ${e.data.size} bytes, type: ${e.data.type}`);
          transcribeAudio();
        } else {
          debugLog('Received empty audio chunk, skipping');
        }
      };

      mediaRecorderRef.current.onstart = () => {
        debugLog('MediaRecorder started event fired');
      };

      mediaRecorderRef.current.onstop = () => {
        debugLog('MediaRecorder stopped event fired');
        transcribeAudio();
      };

      mediaRecorderRef.current.onerror = (event) => {
        debugLog(`MediaRecorder error event: ${JSON.stringify(event)}`);
      };

      mediaRecorderRef.current.start(1000);
      debugLog(`MediaRecorder started, state: ${mediaRecorderRef.current.state}`);
      setIsRecordingLocal(true);
      setIsRecording(true);

      timeoutRef.current = setTimeout(() => {
        debugLog('Recording timeout reached (30 seconds)');
        stopRecording();
      }, 30000);
    } catch (error) {
      logger.error('Error accessing microphone:', error);
      let errorMessage = 'Failed to start recording. ';
      if (error instanceof DOMException) {
        debugLog(`DOMException caught: ${error.name}`);
        switch (error.name) {
          case 'NotAllowedError':
            errorMessage += 'Microphone permission was denied. Please grant permission and try again.';
            break;
          case 'NotFoundError':
            errorMessage += 'No microphone was found on your device.';
            break;
          case 'NotReadableError':
            errorMessage += 'Your microphone is busy or unavailable.';
            break;
          default:
            errorMessage += 'Please check your microphone permissions and try again.';
        }
      } else {
        debugLog(`Non-DOMException caught: ${(error as Error).message}`);
        errorMessage += 'An unexpected error occurred.';
      }
      if (ios) {
        errorMessage += ' On iOS, make sure you have granted microphone permissions in your device settings.';
      }
      logger.error(errorMessage);
    }
  };

  const transcribeAudio = async () => {
    debugLog('Starting transcription process');
    if (chunksRef.current.length === 0) {
      debugLog('No audio data to transcribe');
      return;
    }

    const audioBlob = new Blob(chunksRef.current, { type: mediaRecorderRef.current?.mimeType || 'audio/webm' });
    debugLog(`Audio blob created, size: ${audioBlob.size} bytes, type: ${audioBlob.type}`);

    if (audioBlob.size === 0) {
      debugLog('Audio blob is empty, skipping transcription');
      return;
    }

    try {
      const fileExtension = audioBlob.type.includes('mp4') ? 'mp4' : 'webm';
      const file = new File([audioBlob], `audio.${fileExtension}`, { type: audioBlob.type });
      debugLog(`File created, name: ${file.name}, type: ${file.type}, size: ${file.size} bytes`);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('model', 'whisper-1');
      formData.append('language', sourceLanguage);

      debugLog(`Sending transcription request to OpenAI API. File name: ${file.name}, type: ${file.type}, Language: ${sourceLanguage}`);
      const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: formData,
      });

      debugLog(`OpenAI API response status: ${response.status}`);
      const responseText = await response.text();
      debugLog(`OpenAI API response text: ${responseText}`);

      if (!response.ok) {
        logger.error('OpenAI API Error:', responseText);
        throw new Error(`Failed to transcribe audio: ${response.status} ${response.statusText}\n${responseText}`);
      }

      const data = JSON.parse(responseText);
      debugLog(`Transcription received: ${JSON.stringify(data)}`);
      if (data.text) {
        onSpeechToText(data.text);
      } else {
        debugLog('No transcription text in the API response');
        logger.error('No transcription received. Please try again.');
      }
    } catch (error) {
      logger.error('Error transcribing audio:', error);
    }
  };

  const stopRecording = () => {
    debugLog('Stopping recording');
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      debugLog(`MediaRecorder state before stopping: ${mediaRecorderRef.current.state}`);
      mediaRecorderRef.current.stop();
      debugLog(`MediaRecorder state after stopping: ${mediaRecorderRef.current.state}`);
    } else {
      debugLog(`MediaRecorder not active or null. Current state: ${mediaRecorderRef.current?.state}`);
    }
    if (streamRef.current) {
      debugLog('Stopping all tracks in the media stream');
      streamRef.current.getTracks().forEach(track => {
        track.stop();
        debugLog(`Track stopped: ${track.kind}, ${track.label}, enabled: ${track.enabled}`);
      });
    } else {
      debugLog('No media stream to stop');
    }
    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      debugLog(`Closing AudioContext. Current state: ${audioContextRef.current.state}`);
      audioContextRef.current.close();
    } else {
      debugLog(`AudioContext not active or null. Current state: ${audioContextRef.current?.state}`);
    }
    if (timeoutRef.current) {
      debugLog('Clearing recording timeout');
      clearTimeout(timeoutRef.current);
    }
    setIsRecordingLocal(false);
    setIsRecording(false);
  };

  const toggleRecording = () => {
    debugLog(`Toggling recording. Current state: ${isRecording ? 'recording' : 'not recording'}`);
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  return (
    <Button
      variant="contained"
      color={isRecording ? "secondary" : "primary"}
      onClick={toggleRecording}
      disabled={disabled || !window.isSecureContext || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia}
      style={buttonStyle}
    >
      {isRecording ? <StopIcon /> : <MicIcon />}
    </Button>
  );
};

export default MicrophoneButton;