import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
  },
  palette: {
    primary: {
      main: "#daf4fe",
    },
    secondary: {
      main: "#9ad7f5",
    }
  },
});