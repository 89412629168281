import { AIMessage, ChatCompletionContentPart } from '../types/ai';

export enum LogLevel {
  VERBOSE = 'VERBOSE',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

interface LoggerConfig {
  level: LogLevel;
  enableConsole: boolean;
}

class Logger {
  private config: LoggerConfig;

  constructor() {
    this.config = {
      level: process.env.NODE_ENV === 'production' ? LogLevel.INFO : LogLevel.DEBUG,
      enableConsole: process.env.NODE_ENV !== 'production',
    };
  }

  setConfig(config: Partial<LoggerConfig>) {
    this.config = { ...this.config, ...config };
  }

  private shouldLog(level: LogLevel): boolean {
    const levels = Object.values(LogLevel);
    return levels.indexOf(level) >= levels.indexOf(this.config.level);
  }

  private getTimestamp(): string {
    return new Date().toISOString();
  }

  private log(level: LogLevel, message: string, ...args: unknown[]) {
    if (this.shouldLog(level) && this.config.enableConsole) {
      const logMethod = level === LogLevel.DEBUG ? 'log' : level.toLowerCase();
      console[logMethod as 'log' | 'info' | 'warn' | 'error'](
        `[${this.getTimestamp()}] [${level}] ${message}`,
        ...args
      );
    }
  }

  verbose(message: string, ...args: unknown[]) {
    this.log(LogLevel.VERBOSE, message, ...args);
  }

  debug(message: string, ...args: unknown[]) {
    this.log(LogLevel.DEBUG, message, ...args);
  }

  info(message: string, ...args: unknown[]) {
    this.log(LogLevel.INFO, message, ...args);
  }

  warn(message: string, ...args: unknown[]) {
    this.log(LogLevel.WARN, message, ...args);
  }

  error(message: string, ...args: unknown[]) {
    this.log(LogLevel.ERROR, message, ...args);
  }

  private formatContent(content: string | ChatCompletionContentPart[] | null | undefined): string {
    if (content === null || content === undefined) {
      return 'No content';
    }
    if (typeof content === 'string') {
      return content.replace(/\n/g, '\n    ');
    }
    return content.map(part => 
      `${part.type}: ${part.text?.replace(/\n/g, '\n    ') || 'No text'}`
    ).join('\n    ');
  }

  private formatAIMessages(messages: AIMessage[]): string {
    return messages.map((msg, index) => {
      return `Message ${index + 1}:
  Role: ${msg.role}
  Content: ${this.formatContent(msg.content)}`;
    }).join('\n\n');
  }

  logAIPrompt(messages: AIMessage[]) {
    const formattedMessages = this.formatAIMessages(messages);
    this.debug('[AI Prompt]\n' + formattedMessages);
  }

  logAIResponse(response: unknown) {
    this.debug('[AI Raw Response]', JSON.stringify(response, null, 2));
  }

  logParsedResult(result: unknown) {
    this.debug('[AI Parsed Result]', JSON.stringify(result, null, 2));
  }
}

export const logger = new Logger();