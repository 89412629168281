import { Translations } from '../types';

const ko: Translations['ko'] = {
  appTitle: "몽몽",
  uploadImage: "이미지 업로드",
  selectLanguage: "언어 선택",
  translate: "번역",
  sourceLanguage: "원본 언어",
  targetLanguage: "대상 언어",
  enterText: "번역할 텍스트 입력",
  translationResults: "번역 결과",
  cameraButtonLabel: "사진 찍기",
  microphoneButtonLabel: "녹음 시작",
  speakerButtonLabel: "오디오 재생",
  contextSelectorLabel: "컨텍스트 선택",
  settings: "설정",
  language: "언어",
  gender: "성별",
  genderUnknown: "알 수 없음",
  genderMale: "남성",
  genderFemale: "여성",
  emojiUsage: "이모티콘 사용",
  emojiUsageNever: "사용 안 함",
  emojiUsageSometimes: "가끔 사용",
  emojiUsageAlways: "항상 사용",
  version: "버전",
  releaseDate: "출시일",
  close: "닫기",
  clear: "지우기",
  explain: "설명",
  additionalContext: "추가 컨텍스트",
  recording: "녹음 중...",
  idiomDetected: "관용구 감지됨",
  copy: "복사",
  en: "영어 (English)",
  ja: "일본어 (日本語)",
  ko: "한국어 (Korean)",
  "zh-CN": "중국어 (간체) (简体中文)",
  "zh-TW": "중국어 (번체) (繁體中文)",
  dk: "덴마크어 (Dansk)",
  fr: "프랑스어 (Français)",
  vi: "베트남어 (Tiếng Việt)",
  sv: "스웨덴어 (Svenska)",
  de: "독일어 (Deutsch)",
  es: "스페인어 (Español)",
  ar: "아랍어 (العربية)",
  bn: "벵골어 (বাংলা)",
  ru: "러시아어 (Русский)",
  uk: "우크라이나어 (Українська)",
  ceb: "세부아노어",
  tl: "타갈로그어",
  it: "이탈리아어 (Italiano)",
  el: "그리스어 (Ελληνικά)",
  "ko (nk)": "한국어, 북한 방언 (조선말)",
  casual: "캐주얼",
  social_media: "소셜 미디어",
};

export default ko;