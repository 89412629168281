import { Translations } from '../types';

const zh: Translations['zh-CN'] | Translations['zh-TW'] = {
  appTitle: "蒙蒙",
  uploadImage: "上传图片",
  selectLanguage: "选择语言",
  translate: "翻译",
  sourceLanguage: "源语言",
  targetLanguage: "目标语言",
  enterText: "输入要翻译的文本",
  translationResults: "翻译结果",
  cameraButtonLabel: "拍照",
  microphoneButtonLabel: "开始录音",
  speakerButtonLabel: "播放音频",
  contextSelectorLabel: "选择上下文",
  settings: "设置",
  language: "语言",
  gender: "性别",
  genderUnknown: "未知",
  genderMale: "男性",
  genderFemale: "女性",
  emojiUsage: "表情符号使用",
  emojiUsageNever: "从不",
  emojiUsageSometimes: "有时",
  emojiUsageAlways: "总是",
  version: "版本",
  releaseDate: "发布日期",
  close: "关闭",
  clear: "清除",
  explain: "解释",
  additionalContext: "附加上下文",
  recording: "录音中...",
  idiomDetected: "检测到习语",
  copy: "复制",
  en: "英语 (English)",
  ja: "日语 (日本語)",
  ko: "韩语 (한국어)",
  "zh-CN": "简体中文",
  "zh-TW": "繁体中文",
  dk: "丹麦语 (Dansk)",
  fr: "法语 (Français)",
  vi: "越南语 (Tiếng Việt)",
  sv: "瑞典语 (Svenska)",
  de: "德语 (Deutsch)",
  es: "西班牙语 (Español)",
  ar: "阿拉伯语 (العربية)",
  bn: "孟加拉语 (বাংলা)",
  ru: "俄语 (Русский)",
  uk: "乌克兰语 (Українська)",
  ceb: "宿务语",
  tl: "他加禄语",
  it: "意大利语 (Italiano)",
  el: "希腊语 (Ελληνικά)",
  "ko (nk)": "朝鲜语（北韩方言） (조선말)",
  casual: "随意",
  social_media: "社交媒体",
};

export default zh;