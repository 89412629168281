import { Translations } from '../types';
import en from './en';
import es from './es';
import fr from './fr';
import ko from './ko';
import ja from './ja';
import zh from './zh';
import dk from './dk';
import vi from './vi';
import sv from './sv';
import de from './de';
import ar from './ar';
import bn from './bn';
import ru from './ru';
import uk from './uk';
import ceb from './ceb';
import tl from './tl';
import it from './it';
import el from './el';

const defaultTranslations = en;

const translations: Translations = {
  en,
  es,
  fr,
  ko,
  ja,
  zh,
  dk,
  vi,
  sv,
  de,
  ar,
  bn,
  ru,
  uk,
  ceb,
  tl,
  it,
  el,
};

export const getTranslation = (lang: string, key: string): string => {
  if (translations[lang] && translations[lang][key]) {
    return translations[lang][key];
  }
  return defaultTranslations[key] || key;
};

export default translations;