import { Translations } from '../types';

const tl: Translations['tl'] = {
  appTitle: "MongMong",
  uploadImage: "Mag-upload ng larawan",
  selectLanguage: "Pumili ng wika",
  translate: "Isalin",
  sourceLanguage: "Pinagmulang wika",
  targetLanguage: "Target na wika",
  enterText: "Maglagay ng teksto para isalin",
  translationResults: "Resulta ng pagsasalin",
  cameraButtonLabel: "Kumuha ng larawan",
  microphoneButtonLabel: "Magsimula ng pagrekord",
  speakerButtonLabel: "I-play ang audio",
  contextSelectorLabel: "Pumili ng konteksto",
  settings: "Mga setting",
  language: "Wika",
  gender: "Kasarian",
  genderUnknown: "Hindi alam",
  genderMale: "Lalaki",
  genderFemale: "Babae",
  emojiUsage: "Paggamit ng emoji",
  emojiUsageNever: "Hindi kailanman",
  emojiUsageSometimes: "Minsan",
  emojiUsageAlways: "Palagi",
  version: "Bersyon",
  releaseDate: "Petsa ng paglabas",
  close: "Isara",
  clear: "Burahin",
  explain: "Ipaliwanag",
  additionalContext: "Karagdagang konteksto",
  recording: "Nagrerekord...",
  idiomDetected: "May natuklasang idyoma",
  copy: "Kopyahin",
  en: "Ingles (English)",
  ja: "Hapon (日本語)",
  ko: "Koreano (한국어)",
  "zh-CN": "Tsino (Pinasimple) (简体中文)",
  "zh-TW": "Tsino (Tradisyonal) (繁體中文)",
  dk: "Danish (Dansk)",
  fr: "Pranses (Français)",
  vi: "Vietnamese (Tiếng Việt)",
  sv: "Swedish (Svenska)",
  de: "Aleman (Deutsch)",
  es: "Espanyol (Español)",
  ar: "Arabo (العربية)",
  bn: "Bengali (বাংলা)",
  ru: "Ruso (Русский)",
  uk: "Ukrainian (Українська)",
  ceb: "Cebuano",
  tl: "Tagalog",
  it: "Italyano (Italiano)",
  el: "Griyego (Ελληνικά)",
  "ko (nk)": "Koreano, North Korean dialect (조선말)",
  casual: "Hindi pormal",
  social_media: "Social media",
};

export default tl;