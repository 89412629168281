import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Box, IconButton, useTheme, CircularProgress } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { LanguageKey, FontSizeKey, ContextKey } from '../types';
import { contextText, FONT_SIZES } from '../utils/constants';
import MicrophoneButton from './MicrophoneButton';
import ImageUploader from './ImageUploader';
import LanguageSelector from './LanguageSelector';
import ContextSelector from './ContextSelector';
import { TranslationFunction } from '../hooks/useTranslation';

interface TranslationFormProps {
  onTranslate: (inputText: string, additionalContext: string) => void;
  onExplain: (inputText: string, additionalContext: string) => void;
  onImageUpload: (file: File) => Promise<void>;
  isTranslating: boolean;
  sourceLang: LanguageKey;
  setSourceLang: (lang: LanguageKey) => void;
  targetLang: LanguageKey;
  setTargetLang: (lang: LanguageKey) => void;
  textFieldRef: React.RefObject<HTMLInputElement>;
  sourceText: string;
  setSourceText: (text: string) => void;
  disableInputButtons: boolean;
  t: TranslationFunction;
}

const TranslationForm: React.FC<TranslationFormProps> = ({
  onTranslate,
  onExplain,
  onImageUpload,
  isTranslating,
  sourceLang,
  setSourceLang,
  targetLang,
  setTargetLang,
  textFieldRef,
  sourceText,
  setSourceText,
  disableInputButtons,
  t
}) => {
  const [fontSize, setFontSize] = useState<FontSizeKey>('large');
  const [context, setContext] = useState<ContextKey>('casual');
  const [additionalContext, setAdditionalContext] = useState(contextText[context]);
  const [isRecording, setIsRecording] = useState(false);
  const [showAdditionalContext, setShowAdditionalContext] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    const handleClear = () => {
      setSourceText('');
      setContext('casual');
      setAdditionalContext(contextText['casual']);
      setShowAdditionalContext(false);
    };

    window.addEventListener('clear-form', handleClear);

    return () => {
      window.removeEventListener('clear-form', handleClear);
    };
  }, [setSourceText]);

  const handleSwap = () => {
    setSourceLang(targetLang);
    setTargetLang(sourceLang);
  };

  const handleContextChange = (newContext: ContextKey) => {
    setContext(newContext);
    setAdditionalContext(contextText[newContext]);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setSourceText(text);

    if (textFieldRef.current) {
      const lines = calculateVisibleRows(textFieldRef);
      if (fontSize === 'large' && lines > 3) setFontSize('medium');
      else if (fontSize === 'medium' && lines > 5) setFontSize('small');
      else if (fontSize === 'medium' && lines < 3) setFontSize('large');
      else if (fontSize === 'small' && lines < 4) setFontSize('medium');
    }
  };

  const calculateVisibleRows = (textFieldRef: React.RefObject<HTMLElement>) => {
    const element = textFieldRef.current;
    if (element) {
      const style = window.getComputedStyle(element);
      const fontSize = parseInt(style.fontSize, 10);
      let lineHeight = parseFloat(style.lineHeight);
      lineHeight = isNaN(lineHeight) ? fontSize * 1.2 : lineHeight;
      return Math.floor(Math.floor(element.scrollHeight) / Math.floor(lineHeight));
    }
    return 1;
  };

  const selectorStyle = {
    '& .MuiInputLabel-root': {
      overflow: 'visible',
      whiteSpace: 'nowrap',
    },
    '& .MuiSelect-select': {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
  };

  const buttonStyle = {
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
  };

  return (
    <Box sx={{ mb: 1, mt: 0 }}>
      <Grid 
        container 
        spacing={1} 
        alignItems="center" 
        sx={{ 
          mb: 2,
          '& > .MuiGrid-item': {
            paddingTop: '14px',
          },
        }}
      >
        <Grid item xs={5}>
          <LanguageSelector
            value={sourceLang}
            onChange={setSourceLang}
            label={t('sourceLanguage')}
            sx={selectorStyle}
            t={t}
          />
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton onClick={handleSwap} size="small">
            <SwapHorizIcon />
          </IconButton>
        </Grid>
        <Grid item xs={5}>
          <LanguageSelector
            value={targetLang}
            onChange={setTargetLang}
            label={t('targetLanguage')}
            sx={selectorStyle}
            t={t}
          />
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Box sx={{ flexGrow: 1, mr: 1 }}>
          <ContextSelector
            value={context}
            onChange={handleContextChange}
            sx={selectorStyle}
          />
        </Box>
        <Button
          variant="contained"
          size="medium"
          onClick={() => setShowAdditionalContext(!showAdditionalContext)}
          sx={{ ...buttonStyle, height: 40 }}
        >
          {showAdditionalContext ? "-CONTEXT" : "+CONTEXT"}
        </Button>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          inputRef={textFieldRef}
          value={sourceText}
          onChange={handleInputChange}
          placeholder={t('enterText')}
          InputProps={{
            style: {
              fontSize: FONT_SIZES[fontSize],
            }
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onTranslate(sourceText, additionalContext)}
          disabled={isTranslating}
          sx={{ ...buttonStyle, flexGrow: 1, height: 40, fontSize: '0.9rem' }}
        >
          {t('translate')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onExplain(sourceText, additionalContext)}
          disabled={isTranslating}
          sx={{ ...buttonStyle, flexGrow: 1, height: 40, fontSize: '0.9rem' }}
        >
          {t('explain')}
        </Button>
        <MicrophoneButton
          onSpeechToText={setSourceText}
          sourceLanguage={sourceLang}
          setIsRecording={setIsRecording}
          buttonStyle={{ ...buttonStyle, height: 40, width: 40, minWidth: 40 }}
          disabled={disableInputButtons}
        />
        <ImageUploader
          onImageUpload={onImageUpload}
          disabled={disableInputButtons}
          buttonStyle={{ ...buttonStyle, height: 40, width: 40, minWidth: 40 }}
        />
      </Box>

      {showAdditionalContext && (
        <TextField
          fullWidth
          multiline
          rows={2}
          variant="outlined"
          value={additionalContext}
          onChange={(e) => setAdditionalContext(e.target.value)}
          placeholder={t('additionalContext')}
          sx={{ mb: 1 }}
        />
      )}

      {isRecording && (
        <Box sx={{ backgroundColor: 'rgba(255, 0, 0, 0.1)', padding: '8px', borderRadius: '4px', textAlign: 'center' }}>
          {t('recording')}
        </Box>
      )}
      {isTranslating && <CircularProgress sx={{ display: 'block', margin: 'auto', my: 1 }} />}
    </Box>
  );
};

export default TranslationForm;