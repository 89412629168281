import React, { useRef, useState, useEffect } from 'react';
import { Grid, Container, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import TranslationForm from './components/TranslationForm';
import TranslationResults from './components/TranslationResults';
import AppHeader from './components/AppHeader';
import SettingsPanel from './components/SettingsPanel';
import { TranslationProvider, useTranslationContext } from './context/TranslationContext';
import { theme } from './utils/theme';
import { logger } from './utils/logger';
import { generateSpeech } from './ai';
import { VERSION, RELEASE_DATE } from './utils/constants';
import { LanguageKey, GenderKey, EmojiUsageKey } from './types';

/**
 * Main application component that manages the overall structure and state of the app.
 */
const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <TranslationProvider>
        <AppContent />
      </TranslationProvider>
    </ThemeProvider>
  );
};

/**
 * AppContent component that contains the main content of the application.
 */
const AppContent: React.FC = () => {
  const {
    isTranslating,
    translations,
    sourceLang,
    targetLang,
    uiLang,
    gender,
    emojiUsage,
    setSourceLang,
    setTargetLang,
    setUiLang,
    setGender,
    setEmojiUsage,
    sourceText,
    setSourceText,
    handleTranslate,
    handleExplain,
    handleExplanationImage,
    cancelTranslation,
    clearTranslations,
    isProcessingImage,
    setIsProcessingImage,
    t,
  } = useTranslationContext();

  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [isExplainMode, setIsExplainMode] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    logger.debug('Language, gender, or emoji usage changed', { sourceLang, targetLang, uiLang, gender, emojiUsage });
  }, [sourceLang, targetLang, uiLang, gender, emojiUsage]);

  const handleClear = () => {
    logger.debug('Clear button clicked');
    
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }
    cancelTranslation();
    clearTranslations();
    setIsExplainMode(false);
    setSourceText('');
    setIsProcessingImage(false);

    const clearEvent = new CustomEvent('clear-form');
    window.dispatchEvent(clearEvent);

    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  const handleSpeak = async (text: string, voice: string): Promise<string> => {
    try {
      logger.debug('Generating speech', { text, voice });
      const audioUrl = await generateSpeech(text, voice as 'alloy' | 'echo' | 'fable' | 'onyx' | 'nova' | 'shimmer');
      return audioUrl;
    } catch (error) {
      logger.error('Error generating speech:', error);
      throw error;
    }
  };

  const onTranslate = (inputText: string, additionalContext: string) => {
    logger.debug('onTranslate called', { inputText, additionalContext, sourceLang, targetLang });
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    setIsExplainMode(false);
    setSourceText(inputText);
    handleTranslate(inputText, additionalContext, newAbortController.signal);
  };

  const onExplain = (inputText: string, additionalContext: string) => {
    logger.debug('onExplain called', { inputText, additionalContext, sourceLang, targetLang });
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    setIsExplainMode(true);
    setSourceText(inputText);
    handleExplain(inputText, additionalContext, newAbortController.signal);
  };

  const handleImageUpload = async (file: File) => {
    logger.debug('handleImageUpload called', { fileName: file.name, fileSize: file.size });
    if (isProcessingImage) {
      logger.debug('Image upload skipped: already processing an image');
      return;
    }
    setIsProcessingImage(true);
    try {
      const maxSize = 5 * 1024 * 1024; // 5MB
      let processedFile = file;

      if (file.size > maxSize) {
        logger.debug('Image too large, resizing...', { originalSize: file.size });
        processedFile = await resizeImage(file, maxSize);
        logger.debug('Image resized', { newSize: processedFile.size });
      }

      const imageContent = await fileToBase64(processedFile);
      logger.debug('Image converted to base64', { contentLength: imageContent.length });
      
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      setIsExplainMode(true);
      await handleExplanationImage(imageContent, newAbortController.signal);

      if (translations.length > 0) {
        setSourceText(translations[0].translation);
      }

      logger.debug('Image processing completed');
    } catch (error) {
      logger.error('Error processing image:', error);
      // Handle the error (e.g., show an error message to the user)
    } finally {
      setIsProcessingImage(false);
      setAbortController(null);
    }
  };

  const handleOpenSettings = () => {
    setIsSettingsOpen(true);
  };

  const handleCloseSettings = () => {
    setIsSettingsOpen(false);
  };

  const handleLanguageChange = (language: LanguageKey) => {
    logger.debug('UI Language change requested', { language });
    setUiLang(language);
  };

  const handleGenderChange = (newGender: GenderKey) => {
    logger.debug('Gender change requested', { newGender });
    setGender(newGender);
  };

  const handleEmojiUsageChange = (newEmojiUsage: EmojiUsageKey) => {
    logger.debug('Emoji usage change requested', { newEmojiUsage });
    setEmojiUsage(newEmojiUsage);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppHeader onClear={handleClear} onOpenSettings={handleOpenSettings} t={t} />
      <Box 
        component="main"
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          mt: { xs: '76px', sm: '76px', md: '76px' },
          px: { xs: 1, sm: 2, md: 3 },
        }}
      >      
        <Container 
          disableGutters 
          maxWidth={false} 
          sx={{ 
            maxWidth: '100%', 
            mx: 'auto',
            px: { xs: 0, sm: 0, md: 0 },
          }}
        >
          <Grid container spacing={0} direction="column" sx={{ width: '100%', margin: 0 }}>
            <Grid item>
              <TranslationForm
                onTranslate={onTranslate}
                onExplain={onExplain}
                onImageUpload={handleImageUpload}
                isTranslating={isTranslating}
                sourceLang={sourceLang}
                setSourceLang={setSourceLang}
                targetLang={targetLang}
                setTargetLang={setTargetLang}
                textFieldRef={textFieldRef}
                sourceText={sourceText}
                setSourceText={setSourceText}
                disableInputButtons={isTranslating || isProcessingImage}
                t={t}
              />
            </Grid>
            <Grid item>
              <TranslationResults
                translations={translations}
                onSpeak={handleSpeak}
                isExplainMode={isExplainMode}
                sourceText={sourceText}
                t={t}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <SettingsPanel
        open={isSettingsOpen}
        onClose={handleCloseSettings}
        t={t}
        currentLanguage={uiLang}
        onLanguageChange={handleLanguageChange}
        currentGender={gender}
        onGenderChange={handleGenderChange}
        currentEmojiUsage={emojiUsage}
        onEmojiUsageChange={handleEmojiUsageChange}
        version={VERSION}
        releaseDate={RELEASE_DATE}
      />
    </Box>
  );
};

const resizeImage = (file: File, maxSize: number): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        let width = img.width;
        let height = img.height;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const aspectRatio = width / height;
        if (width > height) {
          width = Math.sqrt(maxSize * aspectRatio);
          height = width / aspectRatio;
        } else {
          height = Math.sqrt(maxSize / aspectRatio);
          width = height * aspectRatio;
        }

        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          if (blob) {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            resolve(resizedFile);
          } else {
            reject(new Error('Failed to resize image'));
          }
        }, 'image/jpeg', 0.7);
      };
      img.src = e.target?.result as string;
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result.split(',')[1]);
      } else {
        reject(new Error('Failed to convert file to base64'));
      }
    };
    reader.onerror = error => reject(error);
  });
};

export default App;
