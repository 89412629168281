import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, SxProps, Theme, SelectProps } from '@mui/material';
import { LanguageKey } from '../types';
import { languageOptions } from '../utils/constants';
import { TranslationFunction } from '../hooks/useTranslation';

interface LanguageSelectorProps {
  value: LanguageKey;
  onChange: (lang: LanguageKey) => void;
  label?: string;
  sx?: SxProps<Theme>;
  inputProps?: SelectProps['inputProps'];
  t: TranslationFunction;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ value, onChange, label, sx, inputProps, t }) => {
  return (
    <FormControl fullWidth size="small" sx={sx}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value as LanguageKey)}
        inputProps={inputProps}
      >
        {Object.entries(languageOptions).map(([key, translationKey]) => (
          <MenuItem key={key} value={key}>
            {t(translationKey as any)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;