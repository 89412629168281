import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { logger, LogLevel } from './utils/logger';

// Set log level based on environment variable or default to INFO
const logLevel = (process.env.REACT_APP_LOG_LEVEL as LogLevel) || LogLevel.DEBUG;

// Enable console logging based on environment variable or default to true for non-production
const enableConsole = process.env.REACT_APP_ENABLE_CONSOLE_LOGGING !== 'false' && process.env.NODE_ENV !== 'production';

// Configure logger
logger.setConfig({
  level: logLevel,
  enableConsole: enableConsole
});

logger.info(`Application starting. Log level: ${logLevel}, console logging: ${enableConsole ? 'enabled' : 'disabled'}`);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
