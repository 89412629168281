import { Translations } from '../types';

const vi: Translations['vi'] = {
  appTitle: "MongMong",
  uploadImage: "Tải lên hình ảnh",
  selectLanguage: "Chọn ngôn ngữ",
  translate: "Dịch",
  sourceLanguage: "Ngôn ngữ nguồn",
  targetLanguage: "Ngôn ngữ đích",
  enterText: "Nhập văn bản để dịch",
  translationResults: "Kết quả dịch",
  cameraButtonLabel: "Chụp ảnh",
  microphoneButtonLabel: "Bắt đầu ghi âm",
  speakerButtonLabel: "Phát âm thanh",
  contextSelectorLabel: "Chọn ngữ cảnh",
  settings: "Cài đặt",
  language: "Ngôn ngữ",
  gender: "Giới tính",
  genderUnknown: "Không xác định",
  genderMale: "Nam",
  genderFemale: "Nữ",
  emojiUsage: "Sử dụng emoji",
  emojiUsageNever: "Không bao giờ",
  emojiUsageSometimes: "Đôi khi",
  emojiUsageAlways: "Luôn luôn",
  version: "Phiên bản",
  releaseDate: "Ngày phát hành",
  close: "Đóng",
  clear: "Xóa",
  explain: "Giải thích",
  additionalContext: "Bối cảnh bổ sung",
  recording: "Đang ghi âm...",
  idiomDetected: "Đã phát hiện thành ngữ",
  copy: "Sao chép",
  en: "Tiếng Anh (English)",
  ja: "Tiếng Nhật (日本語)",
  ko: "Tiếng Hàn (한국어)",
  "zh-CN": "Tiếng Trung (Giản thể) (简体中文)",
  "zh-TW": "Tiếng Trung (Phồn thể) (繁體中文)",
  dk: "Tiếng Đan Mạch (Dansk)",
  fr: "Tiếng Pháp (Français)",
  vi: "Tiếng Việt",
  sv: "Tiếng Thụy Điển (Svenska)",
  de: "Tiếng Đức (Deutsch)",
  es: "Tiếng Tây Ban Nha (Español)",
  ar: "Tiếng Ả Rập (العربية)",
  bn: "Tiếng Bengal (বাংলা)",
  ru: "Tiếng Nga (Русский)",
  uk: "Tiếng Ukraina (Українська)",
  ceb: "Tiếng Cebuano",
  tl: "Tiếng Tagalog",
  it: "Tiếng Ý (Italiano)",
  el: "Tiếng Hy Lạp (Ελληνικά)",
  "ko (nk)": "Tiếng Hàn, phương ngữ Bắc Triều Tiên (조선말)",
  casual: "Thân mật",
  social_media: "Mạng xã hội",
};

export default vi;