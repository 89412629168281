// This file contains Arabic translations

import { Translations } from '../types';

const ar: Translations['ar'] = {
  appTitle: "مونغ مونغ",
  uploadImage: "تحميل صورة",
  selectLanguage: "اختيار اللغة",
  translate: "ترجمة",
  sourceLanguage: "اللغة المصدر",
  targetLanguage: "اللغة الهدف",
  enterText: "أدخل النص للترجمة",
  translationResults: "نتائج الترجمة",
  cameraButtonLabel: "التقاط صورة",
  microphoneButtonLabel: "بدء التسجيل",
  speakerButtonLabel: "تشغيل الصوت",
  contextSelectorLabel: "اختيار السياق",
  settings: "الإعدادات",
  language: "اللغة",
  gender: "الجنس",
  genderUnknown: "غير معروف",
  genderMale: "ذكر",
  genderFemale: "أنثى",
  emojiUsage: "استخدام الرموز التعبيرية",
  emojiUsageNever: "أبدًا",
  emojiUsageSometimes: "أحيانًا",
  emojiUsageAlways: "دائمًا",
  version: "الإصدار",
  releaseDate: "تاريخ الإصدار",
  close: "إغلاق",
  clear: "مسح",
  explain: "شرح",
  additionalContext: "سياق إضافي",
  recording: "جاري التسجيل...",
  idiomDetected: "تم اكتشاف تعبير اصطلاحي",
  copy: "نسخ",
  en: "الإنجليزية (English)",
  ja: "اليابانية (日本語)",
  ko: "الكورية (한국어)",
  "zh-CN": "الصينية (المبسطة) (简体中文)",
  "zh-TW": "الصينية (التقليدية) (繁體中文)",
  dk: "الدنماركية (Dansk)",
  fr: "الفرنسية (Français)",
  vi: "الفيتنامية (Tiếng Việt)",
  sv: "السويدية (Svenska)",
  de: "الألمانية (Deutsch)",
  es: "الإسبانية (Español)",
  ar: "العربية",
  bn: "البنغالية (বাংলা)",
  ru: "الروسية (Русский)",
  uk: "الأوكرانية (Українська)",
  ceb: "السيبوانو",
  tl: "التاغالوغ",
  it: "الإيطالية (Italiano)",
  el: "اليونانية (Ελληνικά)",
  "ko (nk)": "الكورية، لهجة كوريا الشمالية (조선말)",
  casual: "غير رسمي",
  social_media: "وسائل التواصل الاجتماعي",
};

export default ar;