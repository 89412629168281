// This file contains Bengali translations

import { Translations } from '../types';

const bn: Translations['bn'] = {
  appTitle: "মংমং",
  uploadImage: "ছবি আপলোড করুন",
  selectLanguage: "ভাষা নির্বাচন করুন",
  translate: "অনুবাদ করুন",
  sourceLanguage: "উৎস ভাষা",
  targetLanguage: "লক্ষ্য ভাষা",
  enterText: "অনুবাদ করার জন্য টেক্সট লিখুন",
  translationResults: "অনুবাদের ফলাফল",
  cameraButtonLabel: "ছবি তুলুন",
  microphoneButtonLabel: "রেকর্ডিং শুরু করুন",
  speakerButtonLabel: "অডিও চালান",
  contextSelectorLabel: "প্রসঙ্গ নির্বাচন করুন",
  settings: "সেটিংস",
  language: "ভাষা",
  gender: "লিঙ্গ",
  genderUnknown: "অজানা",
  genderMale: "পুরুষ",
  genderFemale: "মহিলা",
  emojiUsage: "ইমোজি ব্যবহার",
  emojiUsageNever: "কখনও না",
  emojiUsageSometimes: "মাঝে মাঝে",
  emojiUsageAlways: "সবসময়",
  version: "সংস্করণ",
  releaseDate: "প্রকাশের তারিখ",
  close: "বন্ধ করুন",
  clear: "মুছে ফেলুন",
  explain: "ব্যাখ্যা করুন",
  additionalContext: "অতিরিক্ত প্রসঙ্গ",
  recording: "রেকর্ডিং চলছে...",
  idiomDetected: "বাগধারা সনাক্ত করা হয়েছে",
  copy: "কপি করুন",
  en: "ইংরেজি (English)",
  ja: "জাপানি (日本語)",
  ko: "কোরিয়ান (한국어)",
  "zh-CN": "চীনা (সরলীকৃত) (简体中文)",
  "zh-TW": "চীনা (ঐতিহ্যবাহী) (繁體中文)",
  dk: "ডেনিশ (Dansk)",
  fr: "ফরাসি (Français)",
  vi: "ভিয়েতনামী (Tiếng Việt)",
  sv: "সুইডিশ (Svenska)",
  de: "জার্মান (Deutsch)",
  es: "স্পেনীয় (Español)",
  ar: "আরবি (العربية)",
  bn: "বাংলা",
  ru: "রাশিয়ান (Русский)",
  uk: "ইউক্রেনীয় (Українська)",
  ceb: "সেবুয়ানো",
  tl: "তাগালগ",
  it: "ইতালীয় (Italiano)",
  el: "গ্রিক (Ελληνικά)",
  "ko (nk)": "কোরিয়ান, উত্তর কোরিয়ার উপভাষা (조선말)",
  casual: "আনুষ্ঠানিকতাহীন",
  social_media: "সামাজিক মাধ্যম",
};

export default bn;