import { ContextKey, FontSizeKey, LanguageKey, GenderKey, EmojiUsageKey } from '../types';
import packageJson from '../../package.json';

export const languageOptions: Record<LanguageKey, string> = {
  "en": "en",
  "ja": "ja",
  "ko": "ko",
  "zh-CN": "zh-CN",
  "zh-TW": "zh-TW",
  "dk": "dk",
  "fr": "fr",
  "vi": "vi",
  "sv": "sv",
  "de": "de",
  "es": "es",
  "ar": "ar",
  "bn": "bn",
  "ru": "ru",
  "uk": "uk",
  "ceb": "ceb",
  "tl": "tl",
  "it": "it",
  "el": "el",
  "mn": "mn",
  "ko (nk)": "ko (nk)"
};

export const SUPPORTED_LANGUAGES = Object.entries(languageOptions).map(([code, name]) => ({ code, name }));

export const contexts: Record<ContextKey, string> = {
  casual: 'Casual',
  social_media: 'Social Media',
  conversational: 'Conversational',
  dating_m_w: 'Dating 🙆‍♂️ ➡ 🤷‍♀️',
  dating_w_m: 'Dating 🤷‍♀️ ➡ 🙆‍♂️ ',
  professional: 'Professional',
  news: 'News',
  medical: "Medical",
  custom: "Custom"
};

export const contextText: Record<ContextKey, string> = {
  conversational: 'The style should be idiomatic and conversational',
  casual: 'The style should be super super casual, between very close friends, with word choices that are conversational and idiomatic.',
  social_media: 'The style is BEYOND casual. Very very overly casual. This is for a caption on instagram stories for very close friends only. Go overboard making this casual.',
  dating_m_w: `Casual male to younger female (girlfriend). style should be casual, fun and idiomatic when possible. It's okay to occasionally use male pronouns when referring to the speaker. Choose trendy words over formal words, and slang over form grammar. Avoid cringey or awkward phrasing, and aim to be cool and fun but not disrepectful.`,
  dating_w_m: 'Casual female to male (boyfriend), very slightly respectful while maintain casual tone, fun and itiomatic when possible.  If there is a trendy word choice or modern slang, choose that. Also occasionally include an emoji when very appropriate. Avoid cringey or awkward phrasing, and aim to be fun. ',
  professional: 'the style should be professional and formal, when talking to your boss or a client',
  news: 'Please use the style of an overzealous news reporter, who is breathlessly reporting a story',
  medical: 'The goal is to explain the situation to a doctor, or understand what a doctor is saying',
  custom: 'Custom context'
};

export const FONT_SIZES: Record<FontSizeKey, number> = {
  large: 26,
  medium: 19,
  small: 12
};

export const frequencyColorMap: { [key: string]: string } = {
  'daily': '#4CAF50',
  'common': '#FFC107',
  'uncommon': '#FF9800',
  'rare': '#F44336'
};

export const OPENAI_API_KEY = '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'

export const VERSION = packageJson.version;
export const RELEASE_DATE = packageJson.releaseDate;

export const GENDER_OPTIONS: Record<GenderKey, string> = {
  unknown: 'Unknown',
  male: 'Male',
  female: 'Female'
};

export const GENDER_STORAGE_KEY = 'preferredGender';

export const EMOJI_USAGE_OPTIONS: Record<EmojiUsageKey, string> = {
  never: 'Never',
  sometimes: 'Sometimes',
  always: 'Always'
};

export const EMOJI_USAGE_STORAGE_KEY = 'preferredEmojiUsage';