// This file contains Greek translations

import { Translations } from '../types';

const el: Translations['el'] = {
  appTitle: "MongMong",
  uploadImage: "Μεταφόρτωση εικόνας",
  selectLanguage: "Επιλογή γλώσσας",
  translate: "Μετάφραση",
  sourceLanguage: "Γλώσσα προέλευσης",
  targetLanguage: "Γλώσσα στόχος",
  enterText: "Εισάγετε κείμενο για μετάφραση",
  translationResults: "Αποτελέσματα μετάφρασης",
  cameraButtonLabel: "Λήψη φωτογραφίας",
  microphoneButtonLabel: "Έναρξη εγγραφής",
  speakerButtonLabel: "Αναπαραγωγή ήχου",
  contextSelectorLabel: "Επιλογή πλαισίου",
  settings: "Ρυθμίσεις",
  language: "Γλώσσα",
  gender: "Φύλο",
  genderUnknown: "Άγνωστο",
  genderMale: "Άνδρας",
  genderFemale: "Γυναίκα",
  emojiUsage: "Χρήση emoji",
  emojiUsageNever: "Ποτέ",
  emojiUsageSometimes: "Μερικές φορές",
  emojiUsageAlways: "Πάντα",
  version: "Έκδοση",
  releaseDate: "Ημερομηνία κυκλοφορίας",
  close: "Κλείσιμο",
  clear: "Καθαρισμός",
  explain: "Εξήγηση",
  additionalContext: "Πρόσθετο πλαίσιο",
  recording: "Εγγραφή...",
  idiomDetected: "Εντοπίστηκε ιδιωματισμός",
  copy: "Αντιγραφή",
  en: "Αγγλικά (English)",
  ja: "Ιαπωνικά (日本語)",
  ko: "Κορεατικά (한국어)",
  "zh-CN": "Κινεζικά (Απλοποιημένα) (简体中文)",
  "zh-TW": "Κινεζικά (Παραδοσιακά) (繁體中文)",
  dk: "Δανικά (Dansk)",
  fr: "Γαλλικά (Français)",
  vi: "Βιετναμέζικα (Tiếng Việt)",
  sv: "Σουηδικά (Svenska)",
  de: "Γερμανικά (Deutsch)",
  es: "Ισπανικά (Español)",
  ar: "Αραβικά (العربية)",
  bn: "Βεγγαλικά (বাংলা)",
  ru: "Ρωσικά (Русский)",
  uk: "Ουκρανικά (Українська)",
  ceb: "Σεμπουάνο",
  tl: "Ταγκαλόγκ",
  it: "Ιταλικά (Italiano)",
  el: "Ελληνικά",
  "ko (nk)": "Κορεατικά, διάλεκτος Βόρειας Κορέας (조선말)",
  casual: "Καθημερινά",
  social_media: "Μέσα κοινωνικής δικτύωσης",
};

export default el;