import { useState, useEffect } from 'react';
import { RemoteAITranslate, RemoteAIExplain, RemoteAIImageExplain } from '../ai';
import { TranslationVariant, LanguageKey, TranslationOutput, ExplanationOutput, TranslationFunction } from '../types';
import { logger } from '../utils/logger';
import translations, { getTranslation } from '../locales/translations';

export type { TranslationFunction };

export const useTranslation = () => {
  const [isTranslating, setIsTranslating] = useState(false);
  const [translationResults, setTranslationResults] = useState<TranslationVariant[]>([]);
  const [language, setLanguage] = useState<LanguageKey>('en');

  useEffect(() => {
    // Detect browser language
    const browserLang = navigator.language.split('-')[0] as LanguageKey;
    setLanguage(browserLang in translations ? browserLang : 'en');
  }, []);

  const t: TranslationFunction = (key, params) => {
    let translatedText = getTranslation(language, key);
    if (params) {
      Object.entries(params).forEach(([paramKey, paramValue]) => {
        translatedText = translatedText.replace(`{{${paramKey}}}`, String(paramValue));
      });
    }
    return translatedText;
  };

  const changeLanguage = (newLanguage: LanguageKey) => {
    setLanguage(newLanguage);
  };

  const cancelTranslation = () => {
    setIsTranslating(false);
  };

  const clearTranslations = () => {
    setTranslationResults([]);
  };

  const handleTranslate = async (
    inputText: string,
    additionalContext: string,
    sourceLang: LanguageKey,
    targetLang: LanguageKey,
    signal: AbortSignal,
    numTranslations?: number
  ) => {
    setIsTranslating(true);
    setTranslationResults([]);

    const runner = new RemoteAITranslate();

    try {
      const responseGenerator = runner.fetchData({
        prompt: inputText,
        additionalContext,
        sourceLang,
        targetLang,
        numTranslations: numTranslations || (inputText.length < 100 ? 5 : inputText.length < 250 ? 3 : 2),
        modelName: "gpt-4o"
      });

      for await (const response of responseGenerator) {
        void response; // Explicitly ignore the response
        if (signal.aborted) {
          throw new Error('Translation aborted');
        }
        const translationOutput = runner.getData();
        setTranslationResults(translationOutput.map((item: TranslationOutput): TranslationVariant => ({
          ...item,
          idiom: typeof item.idiom === 'boolean' ? item.idiom : item.idiom !== undefined ? item.idiom !== '' : false,
          recommendedVoice: item.recommendedVoice || 'alloy' // Default to 'alloy' if not provided
        })));
      }
    } catch (error) {
      if (error instanceof Error && error.message === 'Translation aborted') {
        console.log('Translation request was cancelled');
      } else {
        console.error('Error during translation:', error);
      }
    } finally {
      setIsTranslating(false);
    }
  };

  const handleExplain = async (
    inputText: string,
    additionalContext: string,
    sourceLang: LanguageKey,
    targetLang: LanguageKey,
    signal: AbortSignal
  ) => {
    setIsTranslating(true);
    setTranslationResults([]);

    const runner = new RemoteAIExplain();

    try {
      const responseGenerator = runner.fetchExplanation({
        inputText,
        additionalContext,
        sourceLang,
        targetLang,
        signal
      });

      for await (const response of responseGenerator) {
        void response; // Explicitly ignore the response
        if (signal.aborted) {
          throw new Error('Explanation aborted');
        }
        const explanationOutput = runner.getData() as ExplanationOutput;
        const explanation: TranslationVariant = {
          ...explanationOutput,
          idiom: typeof explanationOutput.idiom === 'boolean' ? explanationOutput.idiom : explanationOutput.idiom !== undefined ? explanationOutput.idiom !== '' : false,
          recommendedVoice: explanationOutput.recommendedVoice || 'alloy' // Default to 'alloy' if not provided
        };
        setTranslationResults([explanation]);
      }
    } catch (error) {
      if (error instanceof Error && error.message === 'Explanation aborted') {
        console.log('Explanation request was cancelled');
      } else {
        console.error('Error during explanation:', error);
      }
    } finally {
      setIsTranslating(false);
    }
  };

  const handleExplanationImage = async (
    imageContent: string,
    sourceLang: LanguageKey,
    targetLang: LanguageKey,
    signal: AbortSignal
  ) => {
    setIsTranslating(true);
    setTranslationResults([]);

    const runner = new RemoteAIImageExplain();

    try {
      const result = await runner.fetchImageExplanation({
        imageContent,
        sourceLang,
        targetLang,
      });

      if (signal.aborted) {
        throw new Error('Image explanation aborted');
      }

      logger.debug('Image explanation result:', result);

      const explanation: TranslationVariant = {
        ...result,
        idiom: typeof result.idiom === 'boolean' ? result.idiom : result.idiom !== undefined ? result.idiom !== '' : false,
        recommendedVoice: result.recommendedVoice || 'alloy' // Default to 'alloy' if not provided
      };
      setTranslationResults([explanation]);
    } catch (error) {
      if (error instanceof Error && error.message === 'Image explanation aborted') {
        console.log('Image explanation request was cancelled');
      } else {
        console.error('Error during image explanation:', error);
      }
    } finally {
      setIsTranslating(false);
    }
  };

  return {
    isTranslating,
    translations: translationResults,
    handleTranslate,
    handleExplain,
    handleExplanationImage,
    cancelTranslation,
    clearTranslations,
    t,
    changeLanguage
  };
};