import { Translations } from '../types';

const uk: Translations['uk'] = {
  appTitle: "МонгМонг",
  uploadImage: "Завантажити зображення",
  selectLanguage: "Вибрати мову",
  translate: "Перекласти",
  sourceLanguage: "Вихідна мова",
  targetLanguage: "Цільова мова",
  enterText: "Введіть текст для перекладу",
  translationResults: "Результати перекладу",
  cameraButtonLabel: "Зробити фото",
  microphoneButtonLabel: "Почати запис",
  speakerButtonLabel: "Відтворити аудіо",
  contextSelectorLabel: "Вибрати контекст",
  settings: "Налаштування",
  language: "Мова",
  gender: "Стать",
  genderUnknown: "Невідомо",
  genderMale: "Чоловіча",
  genderFemale: "Жіноча",
  emojiUsage: "Використання емодзі",
  emojiUsageNever: "Ніколи",
  emojiUsageSometimes: "Іноді",
  emojiUsageAlways: "Завжди",
  version: "Версія",
  releaseDate: "Дата випуску",
  close: "Закрити",
  clear: "Очистити",
  explain: "Пояснити",
  additionalContext: "Додатковий контекст",
  recording: "Йде запис...",
  idiomDetected: "Виявлено ідіоматичний вираз",
  copy: "Копіювати",
  en: "Англійська (English)",
  ja: "Японська (日本語)",
  ko: "Корейська (한국어)",
  "zh-CN": "Китайська (спрощена) (简体中文)",
  "zh-TW": "Китайська (традиційна) (繁體中文)",
  dk: "Данська (Dansk)",
  fr: "Французька (Français)",
  vi: "В'єтнамська (Tiếng Việt)",
  sv: "Шведська (Svenska)",
  de: "Німецька (Deutsch)",
  es: "Іспанська (Español)",
  ar: "Арабська (العربية)",
  bn: "Бенгальська (বাংলা)",
  ru: "Російська (Русский)",
  uk: "Українська",
  ceb: "Себуанська",
  tl: "Тагальська",
  it: "Італійська (Italiano)",
  el: "Грецька (Ελληνικά)",
  "ko (nk)": "Корейська, північнокорейський діалект (조선말)",
  casual: "Неформальний",
  social_media: "Соціальні мережі",
};

export default uk;