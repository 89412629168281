// This file contains English translations

import { Translations } from '../types';

const en: Translations['en'] = {
  appTitle: "MongMong",
  uploadImage: "Upload Image",
  selectLanguage: "Select Language",
  translate: "Translate",
  sourceLanguage: "Source Language",
  targetLanguage: "Target Language",
  enterText: "Enter text to translate",
  translationResults: "Translation Results",
  cameraButtonLabel: "Take Photo",
  microphoneButtonLabel: "Start Recording",
  speakerButtonLabel: "Play Audio",
  contextSelectorLabel: "Select Context",
  settings: "Settings",
  language: "Language",
  gender: "Gender",
  genderUnknown: "Unknown",
  genderMale: "Male",
  genderFemale: "Female",
  emojiUsage: "Emoji Usage",
  emojiUsageNever: "Never",
  emojiUsageSometimes: "Sometimes",
  emojiUsageAlways: "Always",
  version: "Version",
  releaseDate: "Release Date",
  close: "Close",
  clear: "Clear",
  explain: "Explain",
  additionalContext: "Additional Context",
  recording: "Recording...",
  idiomDetected: "Idiom Detected",
  copy: "Copy",
  en: "English",
  ja: "Japanese (日本語)",
  ko: "Korean (한국어)",
  "zh-CN": "Chinese (Simplified) (简体中文)",
  "zh-TW": "Chinese (Traditional) (繁體中文)",
  dk: "Danish (Dansk)",
  fr: "French (Français)",
  vi: "Vietnamese (Tiếng Việt)",
  sv: "Swedish (Svenska)",
  de: "German (Deutsch)",
  es: "Spanish (Español)",
  ar: "Arabic (العربية)",
  bn: "Bengali (বাংলা)",
  ru: "Russian (Русский)",
  uk: "Ukrainian (Українська)",
  ceb: "Cebuano",
  tl: "Tagalog",
  it: "Italian (Italiano)",
  el: "Greek (Ελληνικά)",
  "ko (nk)": "Korean, North Korea dialect (조선말)",
  casual: "Casual",
  social_media: "Social Media",
};

export default en;