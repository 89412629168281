// This file contains French translations

import { Translations } from '../types';

const fr: Translations['fr'] = {
  appTitle: "MongMong",
  uploadImage: "Télécharger une image",
  selectLanguage: "Sélectionner la langue",
  translate: "Traduire",
  sourceLanguage: "Langue source",
  targetLanguage: "Langue cible",
  enterText: "Entrez le texte à traduire",
  translationResults: "Résultats de la traduction",
  cameraButtonLabel: "Prendre une photo",
  microphoneButtonLabel: "Commencer l'enregistrement",
  speakerButtonLabel: "Lire l'audio",
  contextSelectorLabel: "Sélectionner le contexte",
  settings: "Paramètres",
  language: "Langue",
  gender: "Genre",
  genderUnknown: "Inconnu",
  genderMale: "Masculin",
  genderFemale: "Féminin",
  emojiUsage: "Utilisation des emojis",
  emojiUsageNever: "Jamais",
  emojiUsageSometimes: "Parfois",
  emojiUsageAlways: "Toujours",
  version: "Version",
  releaseDate: "Date de sortie",
  close: "Fermer",
  clear: "Effacer",
  explain: "Expliquer",
  additionalContext: "Contexte supplémentaire",
  recording: "Enregistrement en cours...",
  idiomDetected: "Expression idiomatique détectée",
  copy: "Copier",
  en: "Anglais (English)",
  ja: "Japonais (日本語)",
  ko: "Coréen (한국어)",
  "zh-CN": "Chinois (simplifié) (简体中文)",
  "zh-TW": "Chinois (traditionnel) (繁體中文)",
  dk: "Danois (Dansk)",
  fr: "Français (French)",
  vi: "Vietnamien (Tiếng Việt)",
  sv: "Suédois (Svenska)",
  de: "Allemand (Deutsch)",
  es: "Espagnol (Español)",
  ar: "Arabe (العربية)",
  bn: "Bengali (বাংলা)",
  ru: "Russe (Русский)",
  uk: "Ukrainien (Українська)",
  ceb: "Cebuano",
  tl: "Tagalog",
  it: "Italien (Italiano)",
  el: "Grec (Ελληνικά)",
  "ko (nk)": "Coréen, dialecte de Corée du Nord (조선말)",
  casual: "Décontracté",
  social_media: "Réseaux sociaux",
};

export default fr;